import React, { useCallback, useContext, useEffect, useState } from "react";

import { Layout, message } from "antd";
import { Outlet, useLocation } from "react-router-dom";
import "../../assets/css/commons.css";
import "../../assets/css/contentBar.css";
import "../../assets/css/services/services.css";
import { changeModalState, ModalState, urlParams } from "../../constants/AppConstants";
import { InvalidModal, LicenseInvalidModal } from "../../pages/utils/CommonVessels";
import { AppContext } from "./../../context/AppContext";
import { openServiceNotAuthorizedModal } from "../../pages/utils/commonUtils";
const { Content } = Layout;

function getDaysDifference(startDay, endDay) {
  var millisBetween = startDay.getTime() - endDay.getTime();
  var days = millisBetween / (1000 * 3600 * 24);
  return Math.round(Math.abs(days));
}

function Contentbar(props) {
  const [activateLicense, setActivateLicense] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const [leftAuthorizeModal, setLeftAuthorizeModal] = useState(false);
  const [allPageContainer, setAllPageContainer] = useState("hs-bg-off-white overflow-auto");
  const [messageLoaded, setMessageLoaded] = useState(false);
  const [appPropertiesLength, setAppPropertiesLength] = useState();
  const [appProperties] = useContext(AppContext);
  let locationHref = window.location.href;
  let fullView = urlParams.get("fullView");
  let sideBar = urlParams.get("sideBar");
  const topBar = urlParams.get("topBar");
  const location = useLocation();
  let path = location.pathname;

  useEffect(() => {
    const pathSegments = path.split("/");
    let headerValue = "";
    if (pathSegments.includes("settings") && pathSegments?.length > 2) {
      headerValue = pathSegments[2];
    } else {
      headerValue = pathSegments[1];
    }
    props.setHeaderTitle(headerValue);

    if (fullView === "false" && sideBar === "false") {
      setAllPageContainer("hs-bg-off-white overflow-auto");
    }
  }, [fullView, path, props]);

  const showLicenseBanner = useCallback(
    (content, messageType) => {
      messageApi.open({
        type: messageType,
        content: content,
        duration: 5,
      });
    },
    [messageApi]
  );

  useEffect(() => {
    if (appProperties !== null && appProperties !== undefined) {
      if (appProperties.licenseObj != null) {
        let licenseInfo = appProperties.licenseObj.licenseDetails;
        if (licenseInfo !== null && licenseInfo !== undefined) {
          var licenseStatus = licenseInfo.licenseStatus;
          console.log(":::::::  inside - content bar - licenseObj.licenseDetails :::: 4444 ::::", licenseStatus);
          if ((licenseStatus === 4 || licenseStatus === 5) && !messageLoaded) {
            var currentDate = new Date();
            var createdDate = new Date(licenseInfo.createdTime);
            var trialExpiryDate = "";
            if (licenseInfo.trialDays !== null) {
              trialExpiryDate = createdDate.setDate(createdDate.getDate() + licenseInfo.trialDays);
            } else {
              trialExpiryDate = createdDate.setDate(createdDate.getDate() + 3);
            }
            var trialExpiryDateObj = new Date(trialExpiryDate);
            // var trialExpiryISODate = trialExpiryDateObj.toLocaleDateString();

            var daysDifference = getDaysDifference(trialExpiryDateObj, currentDate);
            let messageType = "warning";
            let messageContent = "Trial expires in " + daysDifference + " days";
            let isTrialStarted = localStorage.getItem("Trial");
            if (daysDifference === 3) {
              if (isTrialStarted === null) {
                messageContent = "Trial activated successfully. Enjoy your 3 days free trial.";
                messageType = "success";
                localStorage.setItem("Trial", true);
                showLicenseBanner(messageContent, messageType);
              }
              setMessageLoaded(true);
            }
          } else if (licenseStatus > 10 && locationHref.indexOf("/content") === -1 && locationHref.indexOf("account/helloSend/active") === -1) {
            setActivateLicense(true);
            changeModalState(true);
          }
        }
      }
      if (appProperties.authorizeObj) {
        let authObj = appProperties.authorizeObj;
        let leftAuth = authObj?.left?.auth?.authorized;
        const avoidPagesForAuth = ["/channels", "/channels/textsms", `/channels/textsms/${authObj?.right?.service_name}`];
        function shouldAvoidAuth(path) {
          return avoidPagesForAuth.some((avoidPath) => {
            if (path === avoidPath) return true;
            if (path.startsWith(avoidPath + "/")) {
              const remainingPath = path.slice(avoidPath.length + 1);
              return !remainingPath.includes("/");
            }
            return false;
          });
        }

        if (
          leftAuth === false &&
          locationHref.indexOf("account/helloSend/active") === -1 &&
          locationHref.indexOf("/authorize") === -1 &&
          locationHref.indexOf("/teams/landing") === -1
        ) {
          setLeftAuthorizeModal(true);
          changeModalState(true);
        } else {
          setLeftAuthorizeModal(false);
          changeModalState(false);
        }
        let rightAuth = authObj?.right?.auth?.authorized;
        let rightServiceName = authObj?.right?.service_name;
        if (
          rightAuth === false &&
          locationHref.indexOf("/account/" + appProperties.righServiceName + "/authorize") === -1 &&
          locationHref.indexOf("/account") === -1 &&
          locationHref.indexOf("/authorize") === -1 &&
          locationHref.indexOf("/guide") === -1 &&
          licenseStatus < 11 &&
          rightServiceName !== "chatgpt" &&
          !shouldAvoidAuth(path)
        ) {
          if (!ModalState && !authObj?.right?.auth?.ignoreAuthorizedCheck) {
            openServiceNotAuthorizedModal(
              { service_name: rightServiceName, service_display_name: authObj?.right?.service_display_name },
              appProperties
            );
          }
          changeModalState(true);
        } else {
          changeModalState(false);
        }
      } else if (licenseStatus > 10 && locationHref.indexOf("/content") === -1 && locationHref.indexOf("/account/helloSend/phone") === -1) {
        setActivateLicense(true);
        changeModalState(true);
      }
      console.log("leftAuthorizeModal>>>>", leftAuthorizeModal);
    }
  }, [
    appProperties.licenseObj,
    appProperties.authorizeObj,
    appProperties.osyncId,
    path,
    appProperties,
    locationHref,
    messageLoaded,
    showLicenseBanner,
    leftAuthorizeModal,
  ]);

  useEffect(() => {
    setAppPropertiesLength(Object.keys(appProperties).length);
  }, [appProperties]);

  return (
    <>
      {contextHolder}
      {activateLicense ? <LicenseInvalidModal /> : null}
      {leftAuthorizeModal === true && path !== "/zoho-crm/landing" ? <InvalidModal authorizeModal={leftAuthorizeModal} /> : null}

      <Content>
        <div style={{ height: sideBar === "false" && topBar === "false" ? "100vh" : "calc(100vh - 60px)" }} className={allPageContainer}>
          {appPropertiesLength > 0 && <Outlet />}
        </div>
      </Content>
    </>
  );
}

export default Contentbar;
