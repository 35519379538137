import React, { useContext, useEffect, useState } from "react";
import { Radio, Switch, Button, Typography, Space, Row, Col, message, Skeleton } from "antd";
import "../../assets/css/setting.css";
import useHttp from "../../hooks/useHttp";
import { AppContext } from "../../context/AppContext";
import EmailComponent from "./EmailComponent";
import CheckBoxField from "./CheckBoxField";
import { getSettingsConfig } from "./settingConfig";
import { CommonModal, NoChannelPresent } from "../utils/CommonVessels";
import { useBlocker } from "react-router-dom";
import CountriesWithCountryCode from "./CountriesWithCountryCode";
const { Title } = Typography;

const GeneralSettings = () => {
  const [appProperties] = useContext(AppContext);
  const { fetchData } = useHttp();
  const [settingsValue, setSettingsValue] = useState(null);
  const [rolesInitialValue, setRolesInitialValue] = useState(null);
  const [rolesValue, setRolesValue] = useState(null);
  const [initialValue, setInitialValue] = useState(null);
  const [apiBody, setApiBody] = useState([]);
  const [errorObj, setErrorObj] = useState(null);
  const [showSaveSetting, setShowSaveSetting] = useState(false);
  const [settingBtnLoading, setSettingBtnLoading] = useState(false);
  const [settingPageLoaded, setSettingPageLoaded] = useState(false);
  const [moduleListForRoles, setModuleListForRoles] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [settingConfiguration, setSettingConfiguration] = useState([]);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => showSaveSetting && currentLocation.pathname !== nextLocation.pathname);
  const [generalUserSettings, setGeneralUserSettings] = useState();
  const [matchedPhoneAccessValue, setMatchedPhoneAccessValue] = useState();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Changes saved successfully",
    });
  };

  useEffect(() => {
    let settingConfigObj = getSettingsConfig(setSettingsValue, setApiBody, appProperties, setRolesValue);
    appProperties?.controller?.getSettingPreference(settingConfigObj).then(function (settingsObj) {
      setSettingConfiguration(settingsObj);
    });
  }, [appProperties]);

  useEffect(() => {
    fetchData("settings", "GET", null, appProperties)
      .then(function (response) {
        response = JSON.parse(response);
        const rolesMapping = response?.data?.roles;
        let respToObj = response?.data?.data?.settings?.reduce((acc, obj) => {
          let keyName = obj?.settingName_leftServiceId?.split("_")[0];

          acc[keyName] = keyName.includes("emailNotification")
            ? {
                ...obj,
                sendEmail: obj?.settingValue === "true",
                customEmail: obj?.customEmail === "true",
                accountOwner: obj?.accountOwner === "true",
                recordOwner: obj?.recordOwner === "true",
              }
            : keyName.includes("loggingPreference")
            ? {
                default: obj?.settingValue,
                logRecordsFor: obj?.logRecordsFor || "all",
              }
            : obj?.settingValue;
          return acc;
        }, {});
        let rolesResp;
        if (response?.data?.data?.roles && response?.data?.data?.roles?.length > 0) {
          const inboxData = response?.data?.data?.roles.filter((item) => item.module === "INBOX");

          const inboxAccessSettings = inboxData.reduce((acc, item) => {
            const roleName = rolesMapping[item.roleId];
            if (roleName) {
              const roleKey = roleName.toLowerCase();
              acc[roleKey] = item.fullAccess;
            }
            return acc;
          }, {});

          rolesResp = {
            inboxAccessSettings,
          };
        }
        let defaultValue = appProperties?.controller?.defaultSettingValue;
        let defaultRolesValue = { inboxAccessSettings: { admin: true, user: true } };

        if (respToObj) {
          defaultValue = { ...defaultValue, ...respToObj };
        }
        setSettingsValue(defaultValue);
        setInitialValue(defaultValue);
        if (rolesResp) {
          defaultRolesValue = { ...defaultRolesValue, ...rolesResp };
        }
        setRolesValue(defaultRolesValue);
        setRolesInitialValue(defaultRolesValue);

        setModuleListForRoles(rolesMapping);
        setSettingPageLoaded(true);
      })
      .catch((e) => {
        setSettingPageLoaded(true);
      });
  }, [appProperties, fetchData]);

  const handleSave = () => {
    setErrorObj(null);
    const settingsArray = [];
    if (appProperties?.leftServiceId) {
      Object.keys(settingsValue).forEach((key) => {
        const settingName = key;
        let settingValue = settingsValue[key];
        let settingsEntity = {
          settingName_leftServiceId: settingName,
          settingValue: settingValue,
        };
        if (key === "loggingPreference") {
          settingsEntity.settingValue = settingValue?.default;
          settingsEntity.logRecordsFor = settingValue?.logRecordsFor;
        }
        if (key === "emailNotification") {
          settingsEntity.settingValue = settingValue?.sendEmail;
          settingsEntity.recordOwner = settingValue?.recordOwner;
          settingsEntity.accountOwner = settingValue?.accountOwner;
          settingsEntity.customEmail = settingValue?.customEmail;
          settingsEntity.customEmailList = settingValue?.customEmailList;
        }
        if (key === "defaultCountryCode") {
          settingsEntity.countryCode = settingsValue?.countryCode;
        }
        if (apiBody.includes(settingsEntity?.settingName_leftServiceId)) {
          if (key !== "countryCode") {
            settingsArray.push(settingsEntity);
          }
        }
      });
      var rolePayload = [];
      Object.keys(rolesValue).forEach((key) => {
        let roleValue = rolesValue[key];
        var module;
        if (key === "inboxAccessSettings") module = "INBOX";
        const validRoles = Object.keys(roleValue).map((key) => key.toLowerCase());
        const result = Object.keys(moduleListForRoles)
          .filter((roleId) => validRoles.includes(moduleListForRoles[roleId].toLowerCase()))
          .map((roleId) => {
            const userRole = moduleListForRoles[roleId];
            const isFullAccess = roleValue[userRole.toLowerCase()];

            return {
              roleId,
              isFullAccess,
              module,
            };
          });

        rolePayload = [...rolePayload, ...(result || [])];
      });

      let emailObj = settingsArray.find((obj) => obj.settingName_leftServiceId === "emailNotification");

      if (
        emailObj?.settingValue &&
        !emailObj?.recordOwner &&
        !emailObj?.accountOwner &&
        (!emailObj?.customEmailList || emailObj?.customEmailList?.length < 1)
      ) {
        setErrorObj({
          "Email notification": "Please select at least one email address to receive email notifications.",
        });
        return;
      }
      if (emailObj?.settingValue && emailObj?.customEmail && (!emailObj?.customEmailList || emailObj?.customEmailList?.length < 1)) {
        setErrorObj({
          "Email notification": "Please add at least one email address to receive email notifications.",
        });
        return;
      }
      setSettingBtnLoading(true);
      fetchData(
        `settings?leftServiceId=${appProperties?.leftServiceId}&integId=${appProperties?.randomIntegId}`,
        "POST",
        { data: settingsArray, roles: rolePayload },
        appProperties
      ).then(function (response) {
        try {
          response = JSON.parse(response);
          if (response?.data?.status) {
            setShowSaveSetting(false);
            setApiBody([]);
            success();
            setInitialValue(settingsValue);
            setRolesInitialValue(rolesValue);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setSettingBtnLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    const licensedUserDetails = appProperties?.licenseObj?.allLicensedUserDetails;
    const currentUserObj = licensedUserDetails?.find((user) => user?.remoteUserId === appProperties?.userId);
    const roleId = currentUserObj?.roleId;
    const userRoleKey = appProperties?.rolesBasedObject
      ? Object.entries(appProperties.rolesBasedObject).find(([key, value]) => value === "USER")?.[0]
      : undefined;

    if (roleId && userRoleKey && roleId.toString() === userRoleKey) {
      setGeneralUserSettings(false);
    } else {
      setGeneralUserSettings(true);
    }
    const matchedPhoneAccess = appProperties?.licenseObj?.allLicensedUserDetails?.find(
      (user) => user?.remoteUserId === appProperties?.userId
    )?.phoneAccess;
    setMatchedPhoneAccessValue(matchedPhoneAccess);
  }, [appProperties?.licenseObj]);

  useEffect(() => {
    const settingsValueString = JSON.stringify(settingsValue);
    const initialValueString = JSON.stringify(initialValue);
    const newObjectString = JSON.stringify(rolesValue);
    const newObjectInitialString = JSON.stringify(rolesInitialValue);

    if (settingsValueString !== initialValueString || newObjectString !== newObjectInitialString) {
      setShowSaveSetting(true);
    } else {
      setShowSaveSetting(false);
      setApiBody([]);
      setErrorObj(null);
    }
  }, [settingsValue, initialValue, rolesValue, rolesInitialValue]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (showSaveSetting) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [showSaveSetting]);

  const handleCloseModal = () => {
    blocker.reset();
  };
  const handleDiscard = () => {
    setSettingsValue(initialValue);
    blocker.proceed();
  };

  const modalBody = (
    <>
      <div>
        <div className="ms-2">
          <h5 className="hs-color-dark-blue">Unsaved changes</h5>
          <div className="hs-color-dark-blue">Do you want to save or discard changes?</div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end">
        <Button className="mt-2 row-reverse d-inline-block hs-btn-cancel-small w-100 h-45 hs-fs-14" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button className="hs-btn-small ms-3 mt-2 row-reverse d-inline-block w-100 h-45" onClick={handleDiscard}>
          Discard
        </Button>
      </div>
    </>
  );

  return (
    <>
      {contextHolder}
      {appProperties?.installedApps?.some((authorizeData) => authorizeData?.right?.auth?.authorized || matchedPhoneAccessValue !== 0) ? (
        <>
          <CommonModal
            width={350}
            body={modalBody}
            open={blocker.state === "blocked"}
            doOnClose={handleCloseModal}
            cancelButtonProps={false}
            okButtonProps={false}
            modalCloseIcon={true}
            header={<div style={{ cursor: "default" }} className="actionIconsSpriteForSideBar unsaved-modal-icon" />}
          />
          {settingPageLoaded ? (
            <Space className="m-5 mt-0" style={{ padding: "20px" }}>
              <div className={generalUserSettings ? "" : "disabled-general-settings"}>
                {settingsValue && (
                  <div>
                    <div className="d-flex h-45 justify-content-end">
                      {showSaveSetting && (
                        <Button loading={settingBtnLoading} onClick={handleSave} className="hs-btn-small">
                          Save changes
                        </Button>
                      )}
                    </div>
                    {settingConfiguration.map((obj) => (
                      <Row key={obj.key + "row"} style={{ marginBottom: "20px" }}>
                        <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                          <Title className="hs-color-dark-blue hs-fs-14" level={4}>
                            {obj.title}
                          </Title>
                          <p className="hs-fs-12 hs-color-dark-grey ">{obj.description}</p>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={17} xl={17} className="d-flex h-100">
                          <div className="hs-color-dark-blue m-3 p-3 mt-0 me-1 w-100 rounded hs-bg-white ">
                            <div className="w-100">
                              {obj.type === "radio" && (
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                  <div className="hs-color-dark-blue">{obj?.label}</div>
                                  <div className="mb-0" name={obj.key}>
                                    <Radio.Group value={settingsValue?.[obj?.key]}>
                                      {obj.options.map((option) => (
                                        <Radio
                                          className="setting-radio-btn hs-color-dark-grey"
                                          key={option.value}
                                          value={option.value}
                                          onChange={(e) => {
                                            obj?.onchange(obj?.key, e.target.value);
                                          }}
                                        >
                                          {option.label}
                                        </Radio>
                                      ))}
                                    </Radio.Group>
                                  </div>
                                </div>
                              )}
                              {obj.type === "select" && (
                                <div className="w-50">
                                  <div className="hs-color-dark-blue hs-fs-12">{obj?.label}</div>
                                  <div className="mb-0 mt-1" name={obj.key}>
                                    <CountriesWithCountryCode
                                      parentKey="defaultCountryCode"
                                      setSettingsValue={setSettingsValue}
                                      setApiBody={setApiBody}
                                      value={settingsValue.defaultCountryCode}
                                    />
                                  </div>
                                  <div className="hs-color-dark-blue hs-fs-12">{obj?.tag}</div>
                                </div>
                              )}
                              {obj.type === "switch" && (
                                <div className="d-flex align-items-center justify-content-between">
                                  <div>{obj?.label}</div>
                                  {obj.titleSection !== "RemoveSwitch" && (
                                    <div className="mb-0" name={obj.key}>
                                      <Switch
                                        className="addPhoneSwitch"
                                        onChange={(e) => {
                                          obj?.onchange(obj?.parentKey, obj?.key, e);
                                        }}
                                        checked={
                                          obj?.parentKey?.includes("emailNotification")
                                            ? settingsValue?.emailNotification?.[obj?.key]
                                            : settingsValue?.[obj?.key]
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                              {(settingsValue?.[obj?.parentKey]?.[obj?.key] || obj?.skipParentValue) && (
                                <>
                                  {obj.additionalFields &&
                                    obj.additionalFields.map((field, index) => (
                                      <div className={`m-1 mt-2 ${obj.titleSection ? "defaultSelect" : ""}`} key={field?.type + index}>
                                        {field.type === "email" && settingsValue?.emailNotification?.customEmail ? (
                                          <EmailComponent
                                            parentKey={obj?.parentKey}
                                            settingsValue={settingsValue}
                                            field={field}
                                            setErrorObj={setErrorObj}
                                          />
                                        ) : field?.type === "checkbox" ? (
                                          <CheckBoxField
                                            parentKey={obj?.parentKey}
                                            setErrorObj={setErrorObj}
                                            field={field}
                                            settingValue={field?.roleBased ? rolesValue : settingsValue}
                                          />
                                        ) : field?.type === "radio" ? (
                                          <div className="d-flex align-items-center justify-content-between">
                                            <div className="hs-color-dark-blue">{field?.label}</div>
                                            <div className="mb-0" name={field.key}>
                                              <Radio.Group className="text-nowrap" value={settingsValue?.[obj?.parentKey]?.[field?.key]}>
                                                {field.options.map((option) => (
                                                  <Radio
                                                    className="setting-radio-btn hs-color-dark-grey"
                                                    key={option.value}
                                                    value={option.value}
                                                    onChange={(e) => {
                                                      field?.onchange(obj?.parentKey, field?.key, e.target.value);
                                                    }}
                                                    x
                                                  >
                                                    {option.label}
                                                  </Radio>
                                                ))}
                                              </Radio.Group>
                                            </div>
                                          </div>
                                        ) : (
                                          <p className="m-0 hs-fs-12 hs-color-dark-grey">{field?.text}</p>
                                        )}
                                      </div>
                                    ))}
                                </>
                              )}
                            </div>
                            {errorObj?.[obj?.title] && <div className="hs-fs-12 hs-color-red">{errorObj[obj?.title]} </div>}
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </div>
                )}
              </div>
            </Space>
          ) : (
            <div className="m-5 p-3">
              {[...Array(3)].map((_, index) => (
                <div key={index} className="d-flex justify-content-between align-items-center">
                  <Skeleton key={"skeleton" + index} className="m-3" active size="small" />

                  <Skeleton.Input active size={"large"} />
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        <NoChannelPresent />
      )}
    </>
  );
};

export default GeneralSettings;
