import { Image, Typography } from "antd";
import { useMemo, useState } from "react";
import { getRandomNumber } from "../../pages/utils/commonUtils";
import ButtonGroup from "./input/button/ButtonGroup";

export default function ComponentPreview({ componentsData, handleRemove, className = "", source = "unknown", chatView }) {
  const organizedComponentData = useMemo(() => {
    let buttonComponents = [];
    let otherComponents = [];
    componentsData?.forEach((component) => {
      if (component?.type === "buttons") {
        buttonComponents.push(component);
      } else {
        otherComponents.push(component);
      }
    });
    let splitComponentObject = {
      buttons: buttonComponents,
      others: otherComponents,
    };
    return splitComponentObject;
  }, [componentsData]);

  if (!componentsData) {
    return <></>;
  }

  return (
    <div className={`${source === "message-area" ? "hs-preview-container-bg hs-preview-message-area-container  hs-scroll-bar" : ""}`}>
      <div className={`hs-grid-justified-center  ${className}`} style={{ bottom: 0 }}>
        <ComponentsContainer onRemove={handleRemove} componentsData={organizedComponentData} source={source} chatView={chatView} />
      </div>
    </div>
  );
}

function ComponentsContainer({ componentsData, onRemove, source, chatView }) {
  return (
    <>
      <div className={`d-flex gap-1 component-container-${chatView ? "big" : "small"} hs-floating-button-aside-container`}>
        <CardComponent componentsData={componentsData} source={source} chatView={chatView} />
        {(source === "message-area" || source === "sendsms" || source === "campaign") && <DeleteIconContainer onRemove={onRemove} />}
      </div>
    </>
  );
}

function DeleteIconContainer({ onRemove }) {
  return (
    <div className="hs-floating-button-aside">
      <DeleteIcon handleOnClick={onRemove} />
    </div>
  );
}

function DeleteIcon({ handleOnClick }) {
  return <input type="button" className={"unset-button actionIconsSprite hs-delete-icon-small"} onClick={handleOnClick} />;
}

function CardComponent({ componentsData, source, chatView }) {
  return (
    <div className="hs-card-preview">
      <div className="d-flex flex-column align-items-center hs-max-w-20-rem hs-min-w-10-rem gap-2">
        {componentsData?.others && (
          <div
            className={`${
              source === "conversation" || source === "campaign" ? "hs-bg-v-light-grey" : "bg-white card-main-shadow"
            } card-main-content card-main-content-${chatView ? "small" : source === "campaign" ? "" : "big"} p-2 d-flex flex-column gap-2`}
          >
            <OtherComponentsPreview data={componentsData?.others} chatView={chatView} />
          </div>
        )}
        {componentsData?.buttons && (
          <div
            className={`card-buttons-container d-flex flex-column align-items-center w-100 ${
              source === "conversation" || source === "campaign" ? "hs-bg-v-light-grey" : "bg-white"
            }`}
          >
            <ComponentButtonsPreview data={componentsData?.buttons} type="link" source={source} />
          </div>
        )}
      </div>
    </div>
  );
}

function OtherComponentsPreview({ data, chatView }) {
  return (
    <>
      {data.map((component) => {
        if (component.type === "header") {
          return <ComponentHeaderPreview data={component} chatView={chatView} />;
        }
        if (component.type === "body") {
          return <ComponentBodyPreview data={component} chatView={chatView} />;
        }
        if (component.type === "footer") {
          return <ComponentFooterPreview data={component} chatView={chatView} />;
        }
      })}
    </>
  );
}

function ComponentHeaderPreview({ data, chatView }) {
  const renderContent = () => {
    switch (data?.subType) {
      case "image":
        return (
          <Image
            key={getRandomNumber()}
            src={data?.value}
            preview={{
              mask: <div />,
              toolbarRender: () => null,
              maskClassName: "previewMask",
            }}
            className="chatImage mt-1"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = MediaError;
            }}
            style={{
              borderRadius: 12,
              margin: -1,
              padding: "0px 2px 4px 0px",
              minHeight: !chatView ? "13rem" : "8rem",
              height: !chatView ? "13rem" : "8rem",
              objectFit: "cover",
            }}
          />
        );

      case "video":
        return (
          <video
            src={data?.value}
            style={{
              borderRadius: 12,
              margin: -1,
              padding: "0px 2px 4px 0px",
              minHeight: !chatView ? "13rem" : "8rem",
              height: !chatView ? "13rem" : "8rem",
              objectFit: "cover",
            }}
            autoPlay
            loop
            muted
          />
        );
      case "document":
        return (
          <div className="h-100 w-100 mb-2">
            <a key={getRandomNumber(5)} href={data?.mediaFile?.url} target="_blank" rel="noopener noreferrer" className="pe-2 pdfAttch">
              <div className="d-flex align-items-center justify-content-start ">
                <span className={`${data?.mediaFile?.type === "application/pdf" ? "pdf-icon" : "doc-icon"} staticIconsSprite`}></span>
                {data?.mediaFile?.name}
              </div>
            </a>
          </div>
        );

      default:
        return (
          <div className="mb-2">
            <Typography.Paragraph
              ellipsis={{
                rows: chatView ? 4 : 5,
                expandable: true,
                symbol: "See more",
              }}
              className="hs-fs-14 hs-fw-600 hs-text-white-space-pre-wrap"
            >
              {data?.displayText}
            </Typography.Paragraph>
          </div>
        );
    }
  };

  return <>{renderContent()}</>;
}

function ComponentBodyPreview({ data, chatView }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <Typography.Paragraph
      ellipsis={{
        rows: chatView ? 4 : 5,
        expandable: true,
        symbol: "See more",
      }}
      className="hs-fs-10 hs-text-white-space-pre-wrap"
    >
      {data.displayText}
    </Typography.Paragraph>
  );
}

function ComponentFooterPreview({ data, chatView }) {
  return (
    <Typography.Paragraph
      ellipsis={{
        rows: chatView ? 4 : 5,
        expandable: true,
        symbol: "See more",
      }}
      className="hs-placeholder-text hs-text-white-space-pre-wrap"
    >
      {data?.displayText}
    </Typography.Paragraph>
  );
}

function ComponentButtonsPreview({ data, type, source }) {
  const getButtonGroupArray = () => {
    return data.map((button) => {
      return {
        children: button.displayText,
        buttonClassName: source === "message-area" ? "bg-white" : "hs-bg-v-light-grey",
      };
    });
  };
  return (
    <div className="h-100 w-100 d-flex justify-content-center bg-none">
      <ButtonGroup direction="vertical" className="hs-btn-group-w-clamp" buttons={getButtonGroupArray()} type={type} size="s" />
    </div>
  );
}
