import { useContext, useState } from "react";
import { AppContext } from "../../../../context/AppContext";
import { NoChannelPresent } from "../../../utils/CommonVessels";
import "./../../../../assets/css/campaign.css";
import CampaignContent from "./CampaignContent";

export default function Campaign() {
  const [appProperties, setAppProperties] = useContext(AppContext);
  let urlParams = new URLSearchParams(window.location.search);
  const [shrinkView] = useState(urlParams.get("fullView") === "false");

  return (
    <div className={shrinkView ? "" : "h-100 hs-w-90-per p-3"}>
      {appProperties?.serviceInstalled !== false ? (
        <CampaignContent appProperties={appProperties} shrinkView={shrinkView} setAppProperties={setAppProperties} />
      ) : (
        <NoChannelPresent />
      )}
    </div>
  );
}
