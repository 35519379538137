import { Card, Col, Row, Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../assets/css/services/frame.css";
import "../../assets/css/services/textsms.css";
import { ProviderCommonObj, ProviderExtendedCommonObj } from "../../constants/ProviderConstants";
import { AppContext } from "../../context/AppContext";
import useHttp from "../../hooks/useHttp";
import BuyNumber from "../OnboardingComponents/BuyNumber";
import { handleBuyNumberClick } from "../utils/CommonVessels";
import ServicesSidebar from "./ServicesSidebar";

function TextSMS() {
  const [appProperties] = useContext(AppContext);
  const [textSMSServices, setTextSMSServices] = useState({});
  const currentlySupporting = [
    "twilio",
    "ringcentral",
    "hello_send",
    "whatsapp",
    ...(appProperties?.leftServiceName === "teams" ? ["teams_helloSend"] : []),
  ];

  useEffect(() => {
    const getSMSServicesData = (installedApps, availableApps) => {
      var filteredAvailableApps = availableApps.filter((app) => {
        const installedApp = installedApps?.find((installedApp) => installedApp.right?.service_id === app.serviceId);
        return installedApp && installedApp?.right?.auth?.url;
      });
      filteredAvailableApps = availableApps.filter((obj) => !filteredAvailableApps.includes(obj));
      const installedAppsList = installedApps?.filter((app) => app.right?.auth?.url && app.right?.serviceType === "SMS");
      filteredAvailableApps = filteredAvailableApps.filter((obj) => currentlySupporting.includes(obj.name));
      setTextSMSServices({
        installed: installedAppsList,
        available: filteredAvailableApps,
      });
    };
    if (appProperties.apps) {
      getSMSServicesData(appProperties.installedApps, [...appProperties.apps.SMS]);
    }
  }, [appProperties.apps, appProperties.installedApps]);

  return (
    <>
      <TextSMSServices services={textSMSServices} serviceInstalled={appProperties.serviceInstalled} />
    </>
  );
}

export const TextSMSServices = React.memo((props) => {
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = window.location.pathname;
  const { fetchData } = useHttp();
  const [buyNumberLoading, setBuyNumberLoading] = useState(false);
  const [showBuyNumber, setShowBuyNumber] = useState(false);
  let urlParams = new URLSearchParams(window.location.search);
  let onBoardingPage = urlParams.get("onBoardingPage");
  const navigateToAuthPage = (serviceName, serviceId) => {
    appProperties.rightServiceName = serviceName.toLowerCase();
    let assignerObj1 = Object.assign(ProviderCommonObj, ProviderExtendedCommonObj[serviceName]);
    appProperties.providerCommonObj = assignerObj1;
    setAppProperties((prev) => ({ ...prev, ...appProperties }));
    if (serviceName === "hello_send") {
      navigate(`/channels/textsms/helloSend${location.search}`);
    } else if (pathname === "/settings/channels/textsms") {
      navigate(`/settings/channels/textsms/${serviceName}${location.search}`);
    } else {
      navigate(`/channels/textsms/${serviceName}${location.search}`);
    }
  };

  const { services } = props;
  const closeDrawer = () => {
    setShowBuyNumber(false);
  };
  return (
    <>
      <Row className="ms-4 mt-3">
        {props.serviceInstalled && !onBoardingPage ? (
          <Col span={4} lg={4} md={4} xl={4} sm={4} xs={24} className="ms-2">
            <ServicesSidebar />
          </Col>
        ) : null}
        <Col
          style={{ minHeight: "76vh", height: "100%" }}
          span={props.serviceInstalled ? 19 : 24}
          lg={props.serviceInstalled ? 19 : 24}
          md={props.serviceInstalled ? 19 : 24}
          xl={props.serviceInstalled ? 19 : 24}
          sm={props.serviceInstalled ? 19 : 24}
          xs={24}
        >
          {appProperties.serviceInstalled && (
            <div className="ms-4" style={{ fontSize: 14, color: "#030229" }}>
              Connected integrations
            </div>
          )}
          <div
            style={{
              margin: "1rem 1rem",
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(16rem, 0fr))",
            }}
          >
            {services.installed?.length > 0 &&
              services.installed?.map((obj) => (
                <Card
                  className="card mb-3"
                  style={{
                    width: "14rem",
                    height: "11.5rem",
                    border: "none",
                    cursor: "pointer",
                    boxShadow: 0,
                  }}
                  bordered={false}
                  key={obj?.right?.service_name}
                  onClick={() => navigateToAuthPage(obj?.right?.service_name, obj?.right?.service_id)}
                >
                  <div>
                    <div className={`staticIconsSprite textsms-auth-card-icons ${obj?.right?.service_name?.toLowerCase()}`}></div>
                    <div style={{ fontSize: 14, fontWeight: 600, color: "#111827" }} className="mt-2">
                      {obj?.right?.service_display_name}
                    </div>
                    <div style={{ fontSize: 12, color: "#6B7280" }}>
                      Configure your number using {obj?.right?.service_display_name}'s Cloud Communication Platform.
                    </div>
                  </div>
                </Card>
              ))}
          </div>
          <>
            {/* <Divider
              style={{
                minWidth: "50%",
                width: "50%",
                margin: "10px auto",
                border: "1px solid #F3F3F3",
                borderRadius: 20,
              }}
            /> */}
            {!appProperties.serviceInstalled ? (
              <>
                <div className="ms-4 w-100" style={{ fontSize: 20, fontWeight: 600 }}>
                  <span className="d-flex align-items-center ">
                    {" "}
                    Welcome!
                    <span className="actionIconsSprite welcomeOnboarding ms-2"></span>
                  </span>
                </div>
                <div className="ms-4 w-100">Choose your SMS service to connect your number and start seamless messaging.</div>
                <div className="ms-4 mt-4" style={{ fontWeight: 600 }}>
                  Get Started
                </div>
                <div className="mt-3 ms-4" style={{ fontSize: 14, color: "#111827" }}>
                  <span>Already have a number?</span> Configure your SMS service now.
                </div>
              </>
            ) : services?.available?.length > 0 ? (
              <div className="mt-3 ms-4" style={{ fontSize: 14, color: "#030229" }}>
                Available integrations
              </div>
            ) : null}
          </>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(16rem, 0fr))",
              margin: "1rem 1rem",
            }}
          >
            {services?.available?.length > 0 &&
              services?.available?.map((obj) => (
                <Card
                  className="card mb-3"
                  style={{
                    width: "14rem",
                    height: "11.5rem",
                    border: "none",
                    cursor: "pointer",
                    display: obj?.name === "hello_send" ? "none" : "block",
                  }}
                  bordered={false}
                  key={obj?.name}
                  onClick={() => {
                    navigateToAuthPage(obj?.name, obj?.serviceId, "availableIntegrations");
                  }}
                >
                  <div>
                    <div className={`staticIconsSprite textsms-auth-card-icons ${obj?.name?.toLowerCase()}`}></div>
                    <div style={{ fontSize: 14, fontWeight: 600, color: "#111827" }} className="mt-2">
                      {obj?.displayName}
                    </div>
                    <div style={{ fontSize: 12, color: "#6B7280" }}>
                      Configure your number using {obj?.displayName}'s Cloud Communication Platform.
                    </div>
                  </div>
                </Card>
              ))}
          </div>
          {!appProperties.serviceInstalled && (
            <div className="mt-3 ms-4" style={{ fontSize: 14, color: "#111827" }}>
              <span>Don't have a number? Get one from us. Starts at just $1.25.</span>
              <span>
                {" "}
                <Button
                  type="link"
                  className="text-primary"
                  onClick={() => {
                    setLoading(true);
                    handleBuyNumberClick(
                      appProperties,
                      setAppProperties,
                      fetchData,
                      setBuyNumberLoading,
                      setShowBuyNumber,
                      navigate,
                      "hello_send"
                    );
                  }}
                  loading={loading}
                >
                  Buy now.
                </Button>
              </span>
            </div>
          )}
          <BuyNumber open={showBuyNumber} closeDrawer={closeDrawer} />
        </Col>
      </Row>
    </>
  );
});

export default TextSMS;
