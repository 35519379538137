import { Button, Col, Row, Tooltip, Typography } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InfiniteVirtualTable from "../../../../../components/custom/InfiniteVirtualTable";
import useCampaignApi from "../../../../../hooks/useCampaign";
import { ActivityDetailsModal } from "../../../../utils/CommonVessels";
import { getHSFormattedDateString, getMomentDate } from "../../../../utils/dateUtils";
import {
  MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN,
  getCampaignStatusFromStatusCode,
  getClassNameForCampaignStatus,
  getClassNameForCampaignType,
  getStatusTextFromTimeInMillis,
} from "../../../utils/campaignUtils";
import CampaignOverallMetricContainer from "./CampaignOverallMetricContainer";
import { formatPhoneNumber } from "../../../../utils/commonUtils";

const { Text } = Typography;
const NoDataComponent = ({ url, message, buttonName }) => {
  return (
    <>
      <div className="hs-text-muted mb-3">{message}</div>
      {buttonName && url ? (
        <Button className="hs-bg-violet text-white hs-border-10 px-5 med-button">
          <Link className="all-unset" to={url}>
            {buttonName}
          </Link>
        </Button>
      ) : (
        <></>
      )}
    </>
  );
};

function CampaignOverviewContent(props) {
  const { appProperties } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const { getAllCampaigns, loading } = useCampaignApi(appProperties);
  const [analytics, setAnalytics] = useState();
  const [userActivityData, setUserActivityData] = useState();
  const limit = 50;
  const handleRowClick = (record) => {
    let goto = "reports";
    if (record?.status?.status?.toLowerCase() === getCampaignStatusFromStatusCode(2)) {
      goto = "overview";
    }
    navigate(`/campaigns/list/${record.id}/${goto}${location.search}`);
  };

  const [activityDetailsModalOpen, setActivityDetailsModalOpen] = useState(false);

  const overviewTableColumns = [
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      align: "left",
      className: "overviewTableCells",
      width: "23%",
      render: (text, item) => {
        return (
          <div className="w-100 d-flex align-items-center gap-2 cellInnerText">
            <Text className="cellInnerText" ellipsis={{ tooltip: { text } }}>
              {text}
            </Text>
            <Tooltip title={item?.messageBody} className="hs-px-1">
              <div className="actionIconsSprite bg-white previewEyeIcon" />
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Activity",
      dataIndex: "createdBy",
      key: "users",
      align: "left",
      className: "overviewTableCells",
      width: "17%",
      render: (createdByUserObject, item) => {
        if (createdByUserObject) {
          createdByUserObject = JSON.parse(createdByUserObject);
        }
        let modifiedBy = item?.modifiedBy;
        if (modifiedBy) {
          modifiedBy = JSON.parse(modifiedBy);
        }
        const handleModal = (event) => {
          event.stopPropagation();
          setUserActivityData({
            createdBy: createdByUserObject,
            modifiedBy: modifiedBy,
            createdOn: item?.createdOn,
          });
          setActivityDetailsModalOpen(true);
        };
        // createdByUserObject = { ...createdByUserObject, iconUrl: null };
        return (
          <>
            <div className="cellInnerText d-flex align-items-center gap-2 justify-content-start" onClick={handleModal}>
              <Tooltip title={createdByUserObject?.name} className={`${createdByUserObject?.iconUrl ? "createdUser" : ""}`}>
                {createdByUserObject?.iconUrl ? (
                  <img alt="sender_url" src={createdByUserObject?.iconUrl} className="avatar-small-round" />
                ) : createdByUserObject?.name ? (
                  <div className="avatar-small-round bg-light d-flex justify-content-center align-items-center">
                    {createdByUserObject?.name?.slice(0, 1)}
                  </div>
                ) : (
                  <></>
                )}
              </Tooltip>
              {modifiedBy && modifiedBy[0]?.iconUrl && (
                <Tooltip title={modifiedBy[0]?.name}>
                  <img alt="sender_url" src={modifiedBy[0]?.iconUrl} className="avatar-small-round" />
                </Tooltip>
              )}
              {modifiedBy?.length > 1 && (
                <div className="avatar-small-round bg-light px-2 d-flex justify-content-center align-items-center">
                  +{modifiedBy?.length - 1}
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Sender",
      dataIndex: "sender",
      key: "sender",
      align: "left",
      className: "overviewTableCells",
      width: "16%",
      render: (text) => formatPhoneNumber(text),
    },
    {
      title: "Sent to",
      dataIndex: "sent_To",
      key: "sent_To",
      align: "left",
      className: "overviewTableCells",
      width: "23%",
      render: (text) => {
        return (
          <Text className="cellInnerText" ellipsis={{ tooltip: { text } }}>
            {text}
          </Text>
        );
      },
    },
    {
      title: "Created Time",
      dataIndex: "createdOn",
      key: "createdOn",
      align: "left",
      className: "overviewTableCells",
      width: "15%",
      render: (createdTime) => {
        const formattedTime = getMomentDate(createdTime).fromNow(false);
        return (
          <Tooltip title={getHSFormattedDateString(createdTime)}>
            <Text className="cellInnerText">{formattedTime}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      className: "overviewTableCells",
      width: "16%",
      render: (type, item) => {
        const isPausedStatus = item?.status?.status === "PAUSED";
        const className = isPausedStatus ? "" : getClassNameForCampaignType(type);
        return (
          <div className="d-flex justify-content-center">
            <div className={`px-3 hs-w-fit hs-border-20 campaignBadge ${className}`}>{isPausedStatus ? "-" : type}</div>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      className: "overviewTableCells",
      width: "23%",
      render: (obj) => {
        const { sendDate, status } = obj;
        let statusText = status?.toLowerCase();
        let statusClassName = getClassNameForCampaignStatus(statusText);
        statusText = getStatusTextFromTimeInMillis(sendDate, statusText);
        return (
          <div className="d-flex align-items-center justify-content-center">
            <div className={`hs-border-20 hs-px-1 hs-fw-600 ${statusClassName}`}>{statusText}</div>
            {status?.toLowerCase() === "record_limit_exceeded" && (
              <Tooltip className="ms-1" title={MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN.message}>
                <div className="actionIconsSprite failure-icon" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const noDataComponentProps =
    appProperties?.installedApps?.length > 0
      ? {
          message: (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div className="hs-fs-16">Campaign messages will be listed here.</div>
              <div className="hs-fs-12">Campaigns efficiently convey messages to a broader audience, creating impactful engagement.</div>
            </div>
          ),
        }
      : {
          url: `/channels${location.search}`,
          message: (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div className="hs-fs-16">Campaign messages will be listed here.</div>
              <div className="hs-fs-12">Connect your channel and launch your first campaign.</div>
            </div>
          ),
          buttonName: "Add Channel",
        };

  let paginationRef = useRef();
  const fetchDataAndUpdateState = useCallback(async () => {
    try {
      const res = await getAllCampaigns(limit, paginationRef.current);
      if (res?.campaigns?.length > 0) {
        let newData = res?.campaigns
          ?.filter((item) => item.name)
          ?.map((ele, index) => ({
            ...ele,
            sent_To: ele?.csvFileName ? ele?.csvFileName : ele?.sentTo,
            key: index,
            status: ele,
          }));

        setData((prevData) => (prevData ? [...prevData, ...newData] : [...newData]));
        setAnalytics({
          totalCampaignsCount: res?.totalCampaignsCount,
          totalSuccessMessages: res?.totalSuccessMessages,
        });
        paginationRef.current = res?.pagination;
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [getAllCampaigns]);

  const handleClose = () => {
    setActivityDetailsModalOpen(false);
  };

  return (
    <div className="mt-3 mx-3 ms-4 ps-2">
      <div className="rounded h-100 pb-3">
        <CampaignOverallMetricContainer
          totalCampaignsCount={analytics?.totalCampaignsCount ?? 0}
          totalSuccessMessages={analytics?.totalSuccessMessages ?? 0}
          totalSubscribersCount={0}
          optOutCount={0}
        />
      </div>
      <Row className="campaignOverviewTableContainer">
        <div className="rounded-top h-100 bg-white">
          <Row justify={"end"}>
            <Col>
              <div className="my-3 me-5">
                <Link className="all-unset" to={"/campaign" + location.search}>
                  <Button size="large" type="primary" className="hs-bg-violet hs-border-10 navigateToCampaignButton">
                    <div className="d-flex justify-content-center">
                      <div className="actionIconsSprite sendRocketWhite"></div>
                      Create
                    </div>
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
          <ActivityDetailsModal data={userActivityData} handleClose={handleClose} open={activityDetailsModalOpen} />
          <InfiniteVirtualTable
            noDataOverlayIcon={<div className="staticIconsSprite rocketWithAirIcon"></div>}
            noDataComponent={<NoDataComponent {...noDataComponentProps} />}
            fetchFn={fetchDataAndUpdateState}
            handleRowClick={handleRowClick}
            appProperties={appProperties}
            loading={loading}
            columns={overviewTableColumns}
            data={data}
          />
        </div>
      </Row>
    </div>
  );
}

export default CampaignOverviewContent;
