import React, { useContext } from "react";
import { Col, Row, Image, Button } from "antd";
import Classes from "../../assets/css/services/leftAuth.module.css";
import HelloSendLogo from "../../assets/images/HelloSendLogo.png";
import HS_bg from "../../assets/images/HS_bg.png";

import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { AppContext } from "./../../context/AppContext";

function ZohoCRMLandingPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [appProperties] = useContext(AppContext);

  let urlParams = new URLSearchParams(window.location.search);
  const serviceOrigin = urlParams.get("serviceOrigin");
  let leftServiceId = urlParams.get("leftServiceId");
  let rightServiceId = urlParams.get("rightServiceId");

  urlParams.delete("topBar");
  useEffect(() => {
    console.log("appProperties?.authCRM >>>>>>>>>>>>>>>>>>>", appProperties?.authCRM);
    if (appProperties?.authCRM) {
      let params = new URLSearchParams(location.search);
      console.log("appProperties?.authCRM  params>>>>>>>>>>>>>>>>>>>", params);
      params.set("sideBar", "true");
      params.set("topBar", "true");
      let updatedSearch = "?" + params.toString();
      if (appProperties?.leftServiceDisplayName === "Teams") {
        navigate("/inbox" + updatedSearch);
      } else {
        navigate("/channels/textsms" + updatedSearch);
      }
    }
  }, [appProperties?.authCRM]);

  return (
    <>
      <div style={{ height: "100vh" }} className="hs-bg-white">
        <Row>
          <Col span={24}>
            <div className="container" style={{ marginTop: "20vh" }}>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <Image src={HelloSendLogo} preview={false} draggable={false} className="m-auto mb-4" height={50} width={50} />
                <h5 className="m-3 mt-4">Welcome to HelloSend</h5>
                <div className={`${Classes["infoPara"]} hs-fs-14 mb-0 hs-color-dark-grey`}>
                  Connect your Zoho CRM account to get started with HelloSend's powerful features.
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-5 d-flex justify-content-center ms-5">
          <Col xl={5} md={6} xs={7} className={`ms-5 ps-2 ${Classes["zoho-connect-messaging-Ml"]}`}>
            <Row className="actionIconsSprite zoho-connect-messaging ms-4"></Row>
            <Row className="mt-2">1-1 Messaging</Row>
          </Col>
          <Col xl={4} md={6} xs={6} className="ms-2">
            <Row className="actionIconsSprite zoho-connect-campaign ms-3"></Row>
            <Row className="mt-2">Campaign</Row>
          </Col>
          <Col xl={4} md={4} xs={7} className={Classes["zoho-connect-automation-pl"]}>
            <Row className="actionIconsSprite zoho-connect-automation ms-2"></Row>
            <Row className="mt-2">Automation</Row>
          </Col>
        </Row>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <Button
            type=""
            className={`${Classes["zoho-btn"]} hs-bg-violet d-flex align-items-center text-decoration-none`}
            href={
              appProperties?.leftServiceClientDetails?.authorizeUrl
                ? appProperties.leftServiceClientDetails.authorizeUrl +
                  "?response_type=code&client_id=" +
                  appProperties.leftServiceClientDetails.clientId +
                  "&redirect_uri=" +
                  appProperties.leftServiceClientDetails.redirectUrl +
                  "&state=" +
                  leftServiceId +
                  ":::" +
                  rightServiceId +
                  "&scope=" +
                  appProperties.leftServiceClientDetails.authScopes +
                  "&access_type=offline&serviceOrigin=" +
                  serviceOrigin
                : "#"
            }
            target={appProperties?.leftServiceClientDetails?.authorizeUrl ? "_blank" : "_self"}
          >
            Connect with Zoho
            <span className="actionIconsSprite zoho-connect-arrow hs-fw-600"></span>
          </Button>
        </div>
      </div>
    </>
  );
}

export default ZohoCRMLandingPage;
