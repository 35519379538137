import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Image, Row } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/services/services.css";
import Welcome from "../../assets/images/Welcome.png";
import { ASSIST_MAIL, WHATSAPP_DISPLAY_NAME } from "../../constants/AppConstants.js";
import { AppContext } from "../../context/AppContext.js";
import useHttp from "../../hooks/useHttp.jsx";
import { infoNotificationWithLink } from "../../pages/utils/CommonNotifications";
import BuyNumber from "../OnboardingComponents/BuyNumber.jsx";
import { CommonLoadingV2, handleBuyNumberClick } from "../utils/CommonVessels.js";
import ServicesSidebar from "./ServicesSidebar.jsx";

function Services() {
  const [showBuyNumber, setShowBuyNumber] = useState(false);
  const [buynumberLoading, setBuyNumberLoading] = useState(false);
  const [appProperties, setAppProperties] = useContext(AppContext);
  const { fetchData } = useHttp();

  const columnsData = [
    {
      title: "Text SMS",
      path: "textsms",
      icon: "pageIcons textSmsIconSer",
      description: `Connect your SMS provider to engage with your customers.If your SMS provider isn't listed, please email us at ${ASSIST_MAIL}`,
      buttonText: "learn more ",
      buttonLink: "https://help.oapps.xyz/portal/en/kb/articles/text-sms-for-crm",
    },
    {
      title: WHATSAPP_DISPLAY_NAME,
      path: "whatsapp",
      icon: "staticIconsSprite whatsAppIconSer",
      description: `Connect your ${WHATSAPP_DISPLAY_NAME} to maintain a continuous online presence and engage your customers without delays.`,
      buttonText: "learn more ",
      buttonLink: "https://help.oapps.xyz/portal/en/kb/articles/hellosend-whatsapp-integration",
      disabled: false,
    },
  ];

  const navigate = useNavigate();
  const pathname = window.location.pathname;

  function navigateToTextSms(path) {
    const currentParams = new URLSearchParams(window.location.search);
    const newParams = currentParams.toString();
    if (path === "buyNumber") {
      if (appProperties?.licenseHandledBy && appProperties?.licenseHandledBy !== "chargebee") {
        infoNotificationWithLink(
          <div>
            Contact us at
            <span>
              <a target="_blank" rel="noreferrer" href={`mailto:${ASSIST_MAIL}`} style={{ paddingLeft: 5, color: "#605bff" }}>
                {ASSIST_MAIL}
              </a>
            </span>
            <span> to enable additional channels.</span>
          </div>
        );
      } else {
        handleBuyNumberClick(appProperties, setAppProperties, fetchData, setBuyNumberLoading, setShowBuyNumber, navigate, "hello_send");
      }
    } else if (path === "instagram") {
    } else if (pathname === "/settings/channels") {
      navigate(`/settings/channels/${path}?${newParams}`);
    } else {
      navigate(`/channels/${path}?${newParams}`);
    }
  }

  const handleButtonClick = (link) => {
    // Handle button click, e.g., open a link
    window.open(link, "_blank");
  };
  const closeDrawer = () => {
    setShowBuyNumber(false);
  };
  return (
    <div className="ms-4 ps-1 mt-2 ">
      <BuyNumber open={showBuyNumber} closeDrawer={closeDrawer} />
      {buynumberLoading && <CommonLoadingV2 />}
      <Row>
        {appProperties.serviceInstalled ? (
          <Col span={4} lg={4} md={4} xl={4} sm={4} xs={24}>
            <ServicesSidebar />
          </Col>
        ) : null}
        <Col span={appProperties.serviceInstalled ? 20 : 24}>
          <Row justify={"center"}>
            <Col xs={24} style={{ textAlign: "center" }}>
              {!appProperties?.serviceInstalled && (
                <>
                  <Image preview={false} draggable={false} height={30} style={{ width: "auto" }} src={Welcome} alt="welcome" />
                  <h5
                    className="d-flex justify-content-center mt-3 hs-text"
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    Choose a channel, we'll handle the rest with seamless messaging.
                  </h5>
                </>
              )}
              <div>
                <Row justify={"space-around"} className="">
                  {columnsData.map((column, index) => (
                    <Col
                      key={index}
                      xs={24}
                      className="rounded-4 service-card"
                      lg={8}
                      xl={8}
                      offset={index % 2 === 0 ? 1 : 3}
                      style={{
                        cursor: column?.disabled ? "not-allowed" : "inherit",
                        opacity: column?.disabled ? "50%" : "inherit",
                        cursor: column?.disabled ? "not-allowed" : "pointer",
                      }}
                      onClick={() => (!column?.disabled ? navigateToTextSms(column.path) : null)}
                    >
                      <div
                        className="d-flex justify-content-center mb-3"
                        style={{
                          opacity: column.path === "instagram" && 0.5,
                          pointerEvents: column.path === "instagram" && "none",
                        }}
                      >
                        <div className={column.icon}></div>
                      </div>
                      <h6 style={{ color: "#030229", fontWeight: "bold" }}>{column.title}</h6>
                      <div style={{ fontSize: "14px", color: "rgb(3, 2, 41,0.5)" }}>
                        {column.description}{" "}
                        <span>
                          <Button
                            type="link"
                            style={{
                              color: "#009EFD",
                              whiteSpace: "nowrap",
                            }}
                            className="p-0 m-0 h-auto fw-bold"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleButtonClick(column.buttonLink);
                            }}
                          >
                            <span className="d-flex align-items-center">
                              {column.buttonText}
                              <ArrowRightOutlined style={{ verticalAlign: "sub" }} />
                            </span>
                          </Button>
                        </span>
                      </div>
                    </Col>
                  ))}
                </Row>
                {/* Other rows */}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Services;
