import { Menu, Row, Col } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../../assets/css/omessage.css";
import { AppContext } from "../../../context/AppContext";
import SideBarSupportBooking from "./SideBarSupportBooking";
import SideBarCredits from "./SideBarCredits";
import SubAccountsListing from "../../../pages/subAccount/SubAccountsListing";

const { SubMenu } = Menu;

export default function SideBarMenuItem(props) {
  const { toggleSidebar } = props;
  const location = useLocation();
  const [appProperties] = useContext(AppContext);
  const [subMenuOpenKeys, setSubMenuOpenKeys] = useState([]);
  const [sideBarBorderShow, setSideBarBorderShow] = useState(false);
  const [queryParams, setQueryParams] = useState(null);
  const isAutomationEnabled = appProperties?.controller?.sideBarExtend?.automationSideBar;
  const showCredits = appProperties?.savedNumbers?.filter?.((obj) => obj?.isHelloSendPhoneNumber === "true")?.length > 0 ? true : false;

  useEffect(() => {
    if (appProperties?.osyncId) {
      appProperties?.controller?.getUrlParamsForRedirection(true, appProperties).then(function (queryParamsFromApp) {
        setQueryParams(queryParamsFromApp);
      });
    }
  }, [appProperties, appProperties?.osyncId]);

  const topItems = useMemo(
    () =>
      [
        appProperties?.agencyAccount && {
          key: "subAccount",
        },
        {
          key: "inbox",
          label: "Inbox",
          icon: <span className="actionIconsSpriteForSideBar inboxIcon display-inline-block"></span>,
          path: "/inbox",
        },
        {
          key: "campaign",
          label: "Campaign",
          icon: <span className="actionIconsSpriteForSideBar overviewIcon display-inline-block"></span>,
          path: "/campaigns/list",
        },
        isAutomationEnabled && {
          key: "automation",
          label: "Automation",
          icon: <span className="actionIconsSpriteForSideBar automationIcon display-inline-block"></span>,
          children: [
            {
              key: "workflow",
              parentKey: "automation",
              label: "Webhook",
              path: "/automation",
            },
          ],
        },
        {
          key: "settings",
          label: "Settings",
          icon: <span className="actionIconsSpriteForSideBar settingIcon display-inline-block"></span>,
          path: "/settings",
        },
        {
          key: "channels",
          label: "Channels",
          icon: <span className="actionIconsSpriteForSideBar channelsIcon display-inline-block"></span>,
          path: "/channels",
          settingsSidebarAboveSpace: "100px",
        },
      ].filter(Boolean),
    [appProperties?.agencyAccount, isAutomationEnabled]
  );

  const bottomItems = useMemo(
    () =>
      [
        showCredits && {
          key: "credits",
          label: "Check Balance",
          supportBody: "Credits",
          class: "creditsBalance supportBook lh-2",
          type: "credits",
        },
        {
          key: "bookAgent",
          label: "Support",
          description: "Book Agent",
          class: "supportBook",
          icon: <span className="actionIconsSpriteForSideBar bookAgentIcon display-inline-block"></span>,
          type: "popup",
          iframeUrl: "https://oapps.zohobookings.com/portal-embed#/meeting",
        },
      ].filter(Boolean),
    [showCredits]
  );

  const getSelectedAndOpenKeys = () => {
    let selectedKey = "";
    let subMenuOpenKey = "";

    const menuItems = [...topItems, ...bottomItems];
    menuItems.forEach((item) => {
      if (location.pathname.split("/")[1].includes(item.key)) {
        selectedKey = item.key;
      } else if (item.children) {
        item.children.forEach((child) => {
          if (location.pathname.split("/")[1].includes(child?.key)) {
            selectedKey = child?.key;

            subMenuOpenKey = item.key;
          }
        });
      }
    });

    return { selectedKey, subMenuOpenKey };
  };
  const { selectedKey, subMenuOpenKey } = getSelectedAndOpenKeys();

  useEffect(() => {
    const clickedItem = [...topItems, ...bottomItems].find((item) => item.key === selectedKey);
    if (!clickedItem?.children) {
      setSubMenuOpenKeys([]);
    }
  }, [bottomItems, location, topItems, selectedKey]);

  const generateMenuItems = (menuItems) => {
    let updatedSearch = "?" + queryParams.toString();
    return menuItems.map((item) => {
      if (item.key === "subAccount") {
        return (
          <div className={`${sideBarBorderShow ? "px-3" : "px-1 pb-2 d-md-none"}`}>
            <SubAccountsListing
              closeOffCanvas={() => {
                toggleSidebar();
              }}
              selectComponentWidth={sideBarBorderShow ? "90%" : "94%"}
              selectComponentHeight={sideBarBorderShow ? 50 : 60}
            />
          </div>
        );
      }

      if (item.children) {
        return (
          <SubMenu
            key={item.key}
            title={
              <Row align="middle">
                <Col span={7}>{item.icon}</Col>
                <Col span={16} className="interFontWeightMedium">
                  {item.label}
                </Col>
              </Row>
            }
          >
            {item.children.map((child) => (
              <Menu.Item
                onClick={() => {
                  toggleSidebar();
                }}
                key={child?.key}
              >
                <Link to={child?.path + updatedSearch} target={sideBarBorderShow ? "_blank" : "_self"} style={{ textDecoration: "none" }}>
                  <Row align="middle">
                    <Col span={14} className="interFontWeightMedium ms-3">
                      {child?.label}
                    </Col>
                  </Row>
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>
        );
      }

      return (
        <Menu.Item
          onClick={() => {
            toggleSidebar();
          }}
          key={item.key}
          className={item.class ?? ""}
        >
          {item?.type === "popup" ? (
            <SideBarSupportBooking item={item} appProperties={appProperties} />
          ) : item?.type === "credits" ? (
            <SideBarCredits item={item} appProperties={appProperties} />
          ) : (
            <Link
              to={item.path + updatedSearch}
              style={{ textDecoration: "none" }}
              target={sideBarBorderShow ? "_blank" : "_self"}
              className="mb-3"
            >
              <Row align="middle">
                <Col span={6}>{item.icon}</Col>
                <Col span={18} className="interFontWeightMedium">
                  {item.label}
                </Col>
              </Row>
            </Link>
          )}
        </Menu.Item>
      );
    });
  };

  useEffect(() => {
    if (props.sideBarCommonHandling) {
      setSideBarBorderShow(true);
    }
  }, []);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={`d-flex flex-column justify-content-between  ${sideBarBorderShow ? "border-end-0" : "sidebar-menuRightBorder"}`}
      style={{ height: "84vh" }}
    >
      {queryParams && (
        <>
          <Menu
            className={`sidebar-menu  ${sideBarBorderShow ? "border-end-0 sendSmsSideBar" : "sidebar-menuRightBorder"}`}
            mode={"inline"}
            openKeys={subMenuOpenKeys}
            onOpenChange={(e) => {
              setSubMenuOpenKeys(e);
            }}
            selectedKeys={[selectedKey]}
          >
            {generateMenuItems(topItems)}
          </Menu>
          <Menu
            className={`sideBarBottomMenu sidebar-menu ${sideBarBorderShow ? "border-end-0 sendSmsSideBar" : "sidebar-menuRightBorder"}`}
            mode={"inline"}
            selectedKeys={[selectedKey]}
            defaultOpenKeys={[subMenuOpenKey]}
          >
            {generateMenuItems(bottomItems)}
          </Menu>
        </>
      )}
    </div>
  );
}
