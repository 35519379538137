// config/settingsConfig.js
const handleChangeValue = (key, value, setSettingsValue, setApiBody) => {
  setSettingsValue((prev) => ({ ...(prev || []), [key]: value }));
  setApiBody((prev) => [...prev, key]);
};

const handleChildrenAttributeValueChange = (parentKey, key, value, setSettingsValue, setApiBody) => {
  setSettingsValue((prev) => ({
    ...(prev || []),
    [parentKey]: { ...(prev?.[parentKey] || []), [key]: value },
  }));
  setApiBody((prev) => [...prev, parentKey]);
};
const handleChildrenAttributeValueMultiple = (parentKey, keyValueMap, setSettingsValue, setApiBody) => {
  setSettingsValue((prev) => ({
    ...(prev || []),
    [parentKey]: { ...(prev?.[parentKey] || []), ...keyValueMap },
  }));
  setApiBody((prev) => [...prev, parentKey]);
};

export const getSettingsConfig = (setSettingsValue, setApiBody, appProperties, setRolesValue) => [
  {
    title: "Logging Preference",
    description:
      "Specify your preferred method for logging activities to ensure accurate record-keeping and efficient management. If you plan to activate automations for incoming messages, we recommend using the Activities logging method",
    parentKey: "loggingPreference",
    key: "loggingPreference",
    skipParentValue: true,
    additionalFields: [
      {
        key: "default",
        title: "Logging Preference",
        type: "radio",
        label: "How would you like to log sent and received messages?",
        options: [
          { label: "Notes", value: "notes" },
          { label: "Activities", value: "activities" },
        ],
        onchange: (parentKey, key, value) => handleChildrenAttributeValueChange(parentKey, key, value, setSettingsValue, setApiBody),
      },
      {
        key: "logRecordsFor",
        type: "radio",
        options: [
          { label: "All matching records", value: "all" },
          { label: "Recent record", value: "recent" },
        ],
        label: "Where would you prefer to log received message?",
        onchange: (parentKey, key, value) => handleChildrenAttributeValueChange(parentKey, key, value, setSettingsValue, setApiBody),
      },
    ],
  },
  {
    key: "sendEmail",
    parentKey: "emailNotification",
    title: "Email notification",
    description:
      "Configure your settings to receive email notifications for inbound messages. Enter a email address to ensure you get these alerts promptly",
    type: "switch",
    label: "Would you like to enable email notifications for inbound messages?",
    onchange: (parentKey, key, value) => handleChildrenAttributeValueChange(parentKey, key, value, setSettingsValue, setApiBody),
    additionalFields: [
      {
        text: "Send email to ",
      },
      {
        key: "recordOwner",
        type: "checkbox",
        label: "Record Owner",
        onchange: (parentKey, key, value) => handleChildrenAttributeValueChange(parentKey, key, value, setSettingsValue, setApiBody),
      },
      {
        key: "accountOwner",
        type: "checkbox",
        label: `Account Owner (${appProperties?.extensionInstalledUserEmailId || ""})`,
        onchange: (parentKey, key, value) => handleChildrenAttributeValueChange(parentKey, key, value, setSettingsValue, setApiBody),
      },
      {
        key: "customEmail",
        type: "checkbox",
        onchange: (parentKey, key, value) => handleChildrenAttributeValueChange(parentKey, key, value, setSettingsValue, setApiBody),
        label: "Additional Email Addresses",
      },
      {
        key: "customEmailList",
        placeholder: "Enter an email address",
        type: "email",
        onchange: (parentKey, key, value) => handleChildrenAttributeValueChange(parentKey, key, value, setSettingsValue, setApiBody),
        label: "Please provide a email address for these notifications.",
      },
    ],
  },
  {
    key: "defaultCountryCode",
    parentKey: "defaultCountryCode",
    title: "Country Code",
    description:
      "Select a default country code to be automatically applied when a prospect’s phone number does not have a country code. This ensures consistent communication and correct phone number formatting",
    type: "select",
    label: "Country Code",
    tag: "The country code will appear as the default in all locations.",
  },
  {
    key: "inboxAccessSettings",
    parentKey: "inboxAccessSettings",
    title: "Inbox Access Settings",
    titleSection: "RemoveSwitch",
    skipParentValue: true,
    description:
      "Determine who can view full conversation history. You can choose to give access to all users or restrict it to the admin, with non-admins seeing only their own conversations.",
    label: "Show Full Conversations To:",
    type: "switch",
    additionalFields: [
      {
        key: "accountOwner",
        label: "Account Owner",
        type: "checkbox",
        defaultChecked: true,
        disabled: true,
        roleBased: true,
        onchange: (parentKey, key, value) => handleChildrenAttributeValueChange(parentKey, key, value, setRolesValue, setApiBody),
      },
      {
        label: "Admin",
        type: "checkbox",
        onchange: (parentKey, _, value) => {
          let customValue = {};
          if (value === false) {
            customValue.admin = value;
            customValue.user = value;
          } else {
            customValue.admin = value;
          }
          handleChildrenAttributeValueMultiple(parentKey, customValue, setRolesValue, setApiBody);
        },
        roleBased: true,
        key: "admin",
      },
      {
        label: "Users",
        key: "user",
        onchange: (parentKey, _, value) => {
          let customValue = {};
          if (value === true) {
            customValue.admin = value;
            customValue.user = value;
          } else {
            customValue.user = value;
          }
          handleChildrenAttributeValueMultiple(parentKey, customValue, setRolesValue, setApiBody);
        },
        roleBased: true,
        type: "checkbox",
      },
    ],
  },
];
