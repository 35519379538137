import { UserOutlined, LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Dropdown, Layout, Row, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { urlParams } from "./../../constants/AppConstants";
import { AppContext } from "./../../context/AppContext";

import useLicense from "../../hooks/useLicense.jsx";
import SubAccountsListing from "./../../pages/subAccount/SubAccountsListing.jsx";
import { useLocation } from "react-router-dom";
import useHttp from "../../hooks/useHttp.jsx";

const { Header } = Layout;

const titles = {
  channels: "Channels",
  phone: "Add phone",
  templates: "Templates",
  inbox: "Inbox",
  module: "Campaign Modules",
  sendsms: "Send SMS",
  messages: "Bulk SMS",
  campaigns: "Overview",
  campaign: "Campaign",
  chat: "Chat",
  users: "Users",
  workflow: "Automation",
  campaignOverView: "Detailed Overview",
  general: "Admin Settings",
  blockedNumbers: "Blocked Numbers",
};

function Headerbar(props) {
  const { toggleSidebar } = props;
  let topBarNeeded = urlParams.get("topBar");
  let sideBar = urlParams.get("sideBar");

  const location = useLocation();
  if (location.pathname === "/zoho-crm/webhook") {
    sideBar = "false";
    topBarNeeded = "false";
  }
  var sideBarFalse = "block";
  if (location.pathname === "/zoho-crm/landing" || location.pathname === "/teams/landing") {
    sideBarFalse = "d-none";
  }
  const [title, setTitle] = useState(props.headerTitle);
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [userLogo, setUserLogo] = useState();
  const [isTopBarNeeded] = useState(topBarNeeded === undefined || topBarNeeded !== "false");
  const [isSideBar, setIsSideBar] = useState(true);
  const [loginAreaMenuItems, setLoginAreaMenuItems] = useState([]);
  const { fetchStripeURL } = useLicense();
  let onBoardingPage = urlParams.get("onBoardingPage");
  const { fetchData } = useHttp();

  const handleLogout = () => {
    if (appProperties.leftServiceId) {
      fetchData(`logout?leftServiceId=${appProperties?.leftServiceId}`, "DELETE", null, appProperties).then(function (response) {
        response = JSON.parse(response);
        if (response.data.isValid) {
          setAppProperties((prev) => ({ ...prev, authCRM: false }));
          appProperties?.controller?.saveApplicationToken("").then(function () {
            appProperties.setOtpModalState(true);
          });
        }
        // navigate("/teams/landing" + location.search);
      });
    }
  };

  useEffect(() => {
    if (appProperties?.controller?.pricingLink) {
      console.log("appProperties?.controller?.pricingLink >>>>>>>>>", appProperties?.controller?.pricingLink);

      let loginAreaMenuItemList = [];
      const currentUserObj = appProperties?.licenseObj?.allLicensedUserDetails?.find((user) => user?.remoteUserId === appProperties?.userId);
      const roleId = currentUserObj?.roleId?.toString();
      const userRoleKey = Object.entries(appProperties?.rolesBasedObject || {}).find(([_, value]) => value === "USER")?.[0];
      if (appProperties?.licenseObj?.licenseDetails?.licenseStatus === 4 || appProperties?.licenseObj?.licenseDetails?.licenseStatus === 5) {
        appProperties.controller.pricingLink(appProperties).then(function (resp) {
          if (roleId !== userRoleKey) {
            loginAreaMenuItemList.push({
              label: (
                <a style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer" href={resp}>
                  Manage Subscription
                </a>
              ),
              key: "Manage_Subscription",
              icon: <UserOutlined />,
            });
          }
        });
      } else {
        console.log("appProperties?.licenseHandledBy >>>>>>>>>", appProperties?.licenseHandledBy);
        if (appProperties?.licenseHandledBy === "STRIPE") {
          if (roleId !== userRoleKey) {
            loginAreaMenuItemList.push({
              label: (
                <a style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                  Manage Subscription
                </a>
              ),
              key: "Manage_Subscription",
              icon: <UserOutlined />,
            });
          }
        } else {
          loginAreaMenuItemList.push({
            label: "Manage Subscription",
            key: "Manage_Subscription",
            icon: <UserOutlined />,
            "data-cb-type": "portal",
          });
        }
      }
      // let logoutItem = {
      //   label: <div onClick={handleLogout}>Logout</div>,
      //   key: "_logout",
      //   icon: <LogoutOutlined />,
      // };
      // loginAreaMenuItemList.push(logoutItem);
      // Info :: above code will be pushed with teams launch

      setLoginAreaMenuItems(loginAreaMenuItemList);
    }
  }, [appProperties?.controller, appProperties?.licenseHandledBy]);

  useEffect(() => {
    if (appProperties?.headerTitleName) {
      setTitle(appProperties?.headerTitleName);
    }
  }, [appProperties?.headerTitleName]);

  useEffect(() => {
    if (sideBar === "false") {
      setIsSideBar(false);
    }
  }, []);
  useEffect(() => {
    appProperties.headerTitleName = "";
    setTitle(titles[props.headerTitle] || props.headerTitle);
    let userLogoText = appProperties.userLogo;
    if (userLogoText === "") {
      userLogoText = appProperties.userName.charAt(0).toUpperCase();
    }
    setUserLogo(userLogoText);
  }, [props.headerTitle, appProperties.userLogo, appProperties.userName, location.pathname]);

  if (!isTopBarNeeded) {
    return <></>;
  }

  const handleManageSubscription = (e) => {
    console.log("appProperties.licenseHandledBy registerChargeBee>>>>>>>>", appProperties.licenseHandledBy);
    if (appProperties?.licenseHandledBy === "STRIPE") {
      fetchStripeURL(appProperties, menuProps).then(function (stripeLink) {
        window.open(stripeLink.url);
      });
    } else {
      window.Chargebee?.registerAgain();
      console.log("click", e);
    }
  };

  const menuProps = {
    items: loginAreaMenuItems,
    onMouseEnter: handleManageSubscription,
  };
  return (
    <Header
      className={`allPageContainer ${sideBarFalse}`}
      style={{
        padding: 0,
        height: 60,
        zIndex: 3,
        lineHeight: "64px",
        backgroundColor: sideBar === "false" ? "transparent" : "#fff",
        paddingLeft: "0rem",
      }}
    >
      <Row style={{ overflow: "hidden" }}>
        <Col xs={15} md={11} xl={14} className="d-flex align-items-center">
          <Button
            className="d-md-none px-3"
            type="text"
            icon={<MenuOutlined />}
            onClick={() => {
              toggleSidebar();
            }}
          />
          <div className="ps-2 ps-md-0 w-100">
            <h1
              className="ms-4 m-3"
              style={{
                textTransform: "capitalize",
                color: "#111827",
                fontWeight: 700,
                fontSize: 24,
              }}
            >
              {title}
            </h1>
          </div>
        </Col>

        <Col span={4} className="d-none d-md-block">
          <SubAccountsListing selectComponentWidth={"90%"} selectComponentHeight={40} />
        </Col>

        <Col xs={6} md={7} xl={4} className="d-flex align-items-center ">
          {!onBoardingPage ? (
            <Button
              data-sleek
              data-badge-changelog
              className="announcementHover d-flex align-items-center justify-content-center border-0 shadow-0"
              style={{ boxShadow: "none" }}
            >
              <div className="actionIconsSprite announcement"></div>
              <span className="ms-4 d-none d-md-block"> What's New</span>
            </Button>
          ) : null}
        </Col>

        <Col xs={3} md={2}>
          <Space className="d-flex justify-content-end h-100 w-100">
            {isSideBar ? (
              <>
                <Dropdown disabled={!menuProps?.items?.length} menu={menuProps} overlayClassName="profilePopup" trigger={["click"]}>
                  <Button className="ps-0 border-none boxShadowRemove  d-flex justify-content-center h-100 w-100">
                    <Space className="profileAvatarBg h-100 w-100">
                      <Avatar
                        style={{
                          backgroundColor: "#d5fce3",
                          color: "black",
                          verticalAlign: "middle",
                          border: "1px solid #d5fce3",
                        }}
                        size="large"
                        className="manageSubscription"
                        src={userLogo}
                        border="light"
                      >
                        {userLogo}
                      </Avatar>
                      {menuProps?.items?.length > 0 ? <div className="actionIconsSprite headerDropDownIcon" /> : null}
                    </Space>
                  </Button>
                </Dropdown>
              </>
            ) : null}
          </Space>
        </Col>
      </Row>
    </Header>
  );
}

export default Headerbar;
