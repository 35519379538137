import { LoadingOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { ASSIST_MAIL, urlParams } from "../constants/AppConstants";

import { Badge, Button, Col, Divider, Drawer, Dropdown, Image, Menu, Row, Select, Spin, Table, Tag, Popover } from "antd";
import "../assets/css/omessage.css";
import EmtpyBoxSVG from "../assets/svg/empty-box .svg";
import { AppContext } from "../context/AppContext";
import useUsers from "../hooks/useUsers";
import useLicense from "../hooks/useLicense.jsx";
import "./../assets/css/users.css";
import { DeleteComponent, RefreshLicenseDetails } from "./utils/CommonVessels";

import {
  failureNotification,
  failureNotificationWithLink,
  successNotifyWithDescription,
  failureNotificationWithDuration15Seconds,
  failurePricingNotificationWithBtn,
} from "./utils/CommonNotifications";
import { CommonLoading, formatDate } from "./utils/CommonVessels";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("local");

function Users() {
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [show, setShow] = useState(true);
  const { fetchCurrentUsers, deleteUser } = useUsers();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [savedUsers, setSavedUsers] = useState([]);
  const [callSavedUserTable, setCallSavedUserTable] = useState(true);

  let [userId, setUserId] = useState("");
  const [userModal, setUserModal] = useState("");

  const [openPopover, setOpenPopover] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [totalPurchasedUser, setTotalPurchasedUser] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [status, setStatus] = useState();
  const [planName, setPlanName] = useState();
  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const [phobneNumberObj, setPhobneNumberObj] = useState();
  const [addUserButton, setAddUserButton] = useState();
  const [usersList, setUsersList] = useState([]);
  const [osyncUsersId, setOsyncUsersId] = useState();
  const [rolesBased, setRolesBased] = useState();
  const [updatePhoneNumberAccess, setUpdatePhoneNumberAccess] = useState();

  const [responseObj, setResponseObj] = useState();

  const getUsers = () => {
    setIsDrawerOpen(true);
    setUserId("");
    setUserModal("AddUsers");
  };

  const editUserRole = (record) => {
    setIsDrawerOpen(true);
    setUserId("");
    setUserModal("UpdateUsers");
    setVisibleDropdown(null);
    setOsyncUsersId(record?.osyncUserId);
    setUsersList([record?.email]);
    setRolesBased(record?.roleId);
    setUpdatePhoneNumberAccess(record);
  };

  const deleteUsers = (userData) => {
    userId = userData.remoteUserId;
    let payload = {
      leftServiceId: appProperties.leftServiceId,
      rightServiceId: appProperties.rightServiceId,
      osyncUserId: userData.osyncUserId,
    };
    deleteUser(appProperties, payload).then((response) => {
      if (response?.status === 200) {
        appProperties.licenseObj = response.data;
        setResponseObj(response);
        setCallSavedUserTable(true);
        if (response?.data?.status === "failed") {
          failureNotificationWithLink("At least one user must be retained; deletion is restricted.");
        } else {
          successNotifyWithDescription("Delete User", "User deleted. Feel free to add a new one when needed.");
        }
      }
      setOpenPopover((prevOpenPopover) => ({
        ...prevOpenPopover,
        [userId]: false,
      }));
    });
  };
  let urlParams = new URLSearchParams(window.location.search);
  userId = urlParams.get("userId");

  const handleMenuClick = (e, record) => {
    setVisibleDropdown(visibleDropdown === record.remoteUserId ? null : record.remoteUserId);
  };

  const menu = (record) => <UserDelete record={record} />;

  const UserDelete = (props) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const { record } = props;

    const handleDeleteClick = () => {
      setDeleteModalOpen(true);
      setVisibleDropdown(null);
    };

    return (
      <>
        <Menu>
          <Menu.Item key="editTemplate" onClick={() => editUserRole(record)}>
            <div className="actionIconsSprite editTemplate-icon">
              <span className="ps-4 hs-fs-12">Edit</span>
            </div>
          </Menu.Item>
          <Menu.Item key="deletetemplate" onClick={() => handleDeleteClick(record)}>
            <div className="actionIconsSprite deleteTemplate-icon">
              <span className="ps-4 hs-fs-12">Delete</span>
            </div>
          </Menu.Item>
        </Menu>
        <DeleteComponent
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          record={record}
          confirmDeleteIcon={"hs-delete-icon"}
          confirmDeleteBtnName={"Delete"}
          confirmDeleteComponentTitle={"Sure you want to Delete ?"}
          confirmDeleteComponent={"delete this users"}
          appProperties={appProperties}
          handleDelete={deleteUsers}
        />
      </>
    );
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "11rem",
    },
    {
      title: "Role",
      dataIndex: "roleName",
      key: "role",
      width: "9rem",
    },
    {
      title: "Phone Number",
      dataIndex: "roleBasedNumber",
      key: "phoneNumber",
      width: "15rem",
      render: (roleBasedNumber) => {
        const phoneNumbers =
          (Array.isArray(roleBasedNumber) ? roleBasedNumber : [roleBasedNumber])
            .map((num) => num.replace(/\s*\(.*?\)/, ""))
            .filter((num) => /^[+]?[\d\s-]+$/.test(num)) || [];

        const displayLimit = 2;
        const popoverContent = (
          <div className="roles-based-number">
            {phoneNumbers.slice(2).map((num, idx) => (
              <div key={idx} className="mb-2">
                {num.trim()}
              </div>
            ))}
          </div>
        );

        return phoneNumbers.length > 0 ? (
          <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "250px" }}>
            {phoneNumbers.slice(0, displayLimit).map((num, idx) => (
              <Tag key={idx} className="p-1 me-1 hs-border-15 hs-bg-tag-purple border-none">
                {num.trim()}
              </Tag>
            ))}
            <Popover content={popoverContent} title="Phone Numbers" trigger="hover" placement="topLeft">
              {phoneNumbers.length > displayLimit && (
                <Tag className="hs-bg-tag-purple hs-border-15 p-1">+{phoneNumbers.length - displayLimit}</Tag>
              )}
            </Popover>
          </div>
        ) : (
          <span>{roleBasedNumber}</span>
        );
      },
    },
    {
      title: "Created Time",
      key: "createdTime",
      width: "15rem",
      dataIndex: "createdTime",
      render: (text) => {
        return <div>{formatDate(text)}</div>;
      },
    },
    {
      key: "editDeleteSettings",
      render: (record) => (
        <>
          {addUserButton &&
          record.remoteUserId !== (appProperties.userId || userId) &&
          appProperties.extensionInstalledUserEmailId &&
          record.email !== appProperties.extensionInstalledUserEmailId &&
          activeUsers > 0 ? (
            <Dropdown
              rootClassName="editDeleteSettingDropdown"
              overlay={menu(record)}
              trigger={["click"]}
              visible={visibleDropdown === record.remoteUserId}
              onVisibleChange={() => handleMenuClick(null, record)}
            >
              <div className="actionIconsSprite  editDeleteSettingsIcon cursor-pointer"></div>
            </Dropdown>
          ) : (
            ""
          )}
        </>
      ),
      width: "5%",
    },
  ];

  useEffect(() => {
    if (callSavedUserTable) {
      let numberOfActiveUsers = 0;
      let licensedUserDetails = null;
      if (appProperties?.licenseObj) {
        licensedUserDetails = appProperties?.licenseObj?.allLicensedUserDetails;
        var phoneObj = appProperties?.allNumbers?.filter((obj) => obj.phoneNumber);
        setPhobneNumberObj(phoneObj);
      } else if (responseObj?.data) {
        licensedUserDetails = responseObj?.data?.allLicensedUserDetails;
      }
      const savedNumberList = appProperties?.allNumbers?.map((phoneObj) => phoneObj?.phoneNumber);

      if (licensedUserDetails) {
        setShowTable(true);
        const rolesMapping = appProperties?.rolesBasedObject;
        const updatedUserDetails = licensedUserDetails?.map((user) => {
          const phoneNumbers = appProperties?.allNumbers
            ?.filter((obj) => obj?.associatedUsers?.includes(user?.osyncUserId))
            ?.map(
              (e) =>
                e?.phoneNumber +
                " (" +
                appProperties?.installedApps?.find((numberObj) => numberObj?.integProps?.integId === e?.integId)?.right?.service_display_name +
                ")"
            );

          const roleBasedNumber = user?.phoneAccess === 1 ? "All Numbers" : user?.phoneAccess === 2 ? phoneNumbers : "System Default";
          return {
            ...user,
            roleName: rolesMapping[user?.roleId],
            roleBasedNumber,
          };
        });

        const currentUserObj = licensedUserDetails?.find((user) => user?.remoteUserId === appProperties?.userId);
        const roleId = currentUserObj?.roleId;
        const userRoleKey = Object?.entries(appProperties?.rolesBasedObject)?.find(([_, value]) => value === "USER")?.[0];

        if (roleId?.toString() === userRoleKey) {
          setAddUserButton(false);
        } else {
          setAddUserButton(true);
        }

        setSavedUsers(updatedUserDetails);
        numberOfActiveUsers = updatedUserDetails?.length ?? 0;
      }
      setActiveUsers(numberOfActiveUsers);
      setTotalPurchasedUser(appProperties?.licenseObj?.licenseDetails?.totalUsersPurchased);
      setStatus(appProperties?.licenseObj?.licenseDetails?.licenseStatus);
      setPlanName(appProperties?.licenseObj?.licenseDetails?.licensePlanName);
      setCallSavedUserTable(true);
      setShowLoading(false);
    }
    let showAddUser = urlParams.get("showAddUser");
    if (showAddUser && showAddUser === "true") {
      setIsDrawerOpen(true);

      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      params.delete("showAddUser");
      const newEndpoint = "/users";
      const newUrl = `${url.origin}${newEndpoint}?${params.toString()}`;
      window.history.pushState({}, "", newUrl);
    }
  }, [appProperties, appProperties?.allNumbers, callSavedUserTable, responseObj]);

  return (
    <>
      <div className="m-1, mt-3 ms-2">
        <Row align="middle">
          <Col span={9} className="ps-4">
            <div className="d-flex justify-content-start align-items-center">
              <Row className="mt-2">
                <Col style={{ cursor: "default" }} className="actionIconsSprite authInfo-icon"></Col>
                <Col className="ms-2 contentbarHeadingColor">Manage user access for seamless customer interactions.</Col>
              </Row>
            </div>
          </Col>
          <Col span={11}>
            <Row align="middle">
              <Col span={24}>
                <div className="d-flex justify-content-end">
                  <RefreshLicenseDetails usersTabRefreshBtn={true} />
                  <Tag
                    style={{
                      backgroundColor: "#fff",
                      padding: 8,
                      border: 0,
                      borderRadius: 50,
                    }}
                  >
                    Total purchased users
                    <Badge
                      style={{
                        backgroundColor: "#D9D8FF",
                        color: "#605BFF",
                        marginLeft: 5,
                      }}
                      count={totalPurchasedUser}
                    ></Badge>
                  </Tag>
                  <Tag
                    style={{
                      backgroundColor: "#fff",
                      padding: 8,
                      marginLeft: 5,
                      border: 0,
                      borderRadius: 50,
                    }}
                  >
                    Active users
                    <Badge
                      style={{
                        backgroundColor: "#D9D8FF",
                        color: "#605BFF",
                        marginLeft: 5,
                      }}
                      count={activeUsers ? activeUsers : 0}
                    ></Badge>
                  </Tag>
                </div>
              </Col>
            </Row>
          </Col>
          {addUserButton ? (
            <Col span={4}>
              <div className="d-flex justify-content-center align-items-center">
                <Button className="addTempBtn" type="" onClick={getUsers} icon={<div className="actionIconsSprite plusIconWhite" />}>
                  Add Users
                </Button>
              </div>
            </Col>
          ) : null}
        </Row>

        {isDrawerOpen ? (
          <AddUsers
            setIsDrawerOpen={setIsDrawerOpen}
            isDrawerOpen={isDrawerOpen}
            appProperties={appProperties}
            setAppProperties={setAppProperties}
            setCallSavedUserTable={setCallSavedUserTable}
            savedUsers={savedUsers}
            setResponseObj={setResponseObj}
            activeUsers={activeUsers}
            setUserModal={setUserModal}
            userModal={userModal}
            phobneNumberObj={phobneNumberObj}
            usersList={usersList}
            setUsersList={setUsersList}
            osyncUsersId={osyncUsersId}
            rolesBased={rolesBased}
            updatePhoneNumberAccess={updatePhoneNumberAccess}
          />
        ) : null}

        {showLoading ? (
          <CommonLoading />
        ) : showTable ? (
          <Table
            className="savedTempTable automationTable ms-3"
            scroll={{ x: 1100, y: 520 }}
            pagination={false}
            columns={columns}
            dataSource={savedUsers}
          />
        ) : (
          <div className="d-flex justify-content-center">
            <Image style={{ width: "10rem", marginTop: "5rem" }} src={EmtpyBoxSVG} preview={false} />
          </div>
        )}
      </div>
    </>
  );
}

export default Users;

const AddUsers = React.memo((props) => {
  const {
    isDrawerOpen,
    setIsDrawerOpen,
    appProperties,
    setAppProperties,
    setCallSavedUserTable,
    setResponseObj,
    userModal,
    activeUsers,
    savedUsers,
    phobneNumberObj,
    usersList,
    setUsersList,
    osyncUsersId,
    userUpdateList,
    rolesBased,
    updatePhoneNumberAccess,
  } = props;

  const [drawerOpen, setDrawerOpen] = useState(isDrawerOpen);

  const { fetchUsers, addUsers } = useUsers();
  const { fetchStripeURL } = useLicense();

  const [loadingUserResponse, setLoadingUserResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [failurePricing, setFailurePricing] = useState(false);
  const [failedUsersList, setFailedUsersList] = useState();
  const [userLimitError, setUserLimitError] = useState();
  const [userRole, setUserRole] = useState(13);
  const [showAllPhoneNumbers, setShowAllPhoneNumbers] = useState("systemDefault");
  const [assignPhoneNumber, setAssignPhoneNumber] = useState();

  useEffect(() => {
    if (appProperties) {
      setLoadingUserResponse(true);
      fetchUsers(appProperties).then((response) => {
        if (response !== null) {
          setLoadingUserResponse(false);
          let usersList = response.data;
          usersList = usersList?.filter((user) => !savedUsers.some((savedUser) => savedUser.email === user.email));
          setUsers(usersList);
        }
      });
    }
  }, [appProperties]);

  useEffect(() => {
    if (userLimitError) {
      const currentUserObj = appProperties?.licenseObj?.allLicensedUserDetails?.find((user) => user?.remoteUserId === appProperties?.userId);
      const roleId = currentUserObj?.roleId?.toString();
      const userRoleKey = Object.entries(appProperties?.rolesBasedObject || {}).find(([_, value]) => value === "USER")?.[0];
      if (appProperties?.licenseHandledBy === "STRIPE") {
        if (roleId !== userRoleKey) {
          fetchStripeURL(appProperties).then(function (stripeLink) {
            failurePricingNotificationWithBtn("User Limit Reached", failedUsersList, "Manage Subscription", appProperties, stripeLink.url);
            setIsDrawerOpen(false);
          });
        }
      } else {
        if (roleId !== userRoleKey) {
          failurePricingNotificationWithBtn("User Limit Reached", failedUsersList, "Manage Subscription", appProperties);
        }
        setIsDrawerOpen(false);
        setUsersList([]);
      }
    }
  }, [userLimitError]);

  useEffect(() => {
    if (userModal === "UpdateUsers") {
      setUserRole(rolesBased);
      if (Array.isArray(updatePhoneNumberAccess?.roleBasedNumber) && updatePhoneNumberAccess?.phoneAccess === 2) {
        setShowAllPhoneNumbers("assignedNumbersOnly");
        setAssignPhoneNumber(updatePhoneNumberAccess?.roleBasedNumber);
      } else if (updatePhoneNumberAccess?.phoneAccess === 1) {
        setShowAllPhoneNumbers("allNumbers");
      } else {
        setShowAllPhoneNumbers("systemDefault");
      }
    }
  }, [userModal, updatePhoneNumberAccess?.fullPhoneAccess]);

  const addUser = () => {
    var remainingAllowedUsersCount = appProperties?.licenseObj?.licenseDetails?.totalUsersPurchased - activeUsers;
    if (usersList.length > remainingAllowedUsersCount && userModal !== "UpdateUsers") {
      setFailedUsersList(
        "You're adding more users than your current license allows. Please click the 'Manage Subscription' button to upgrade your license and add more users."
      );
      setUserLimitError(true);
    } else {
      setLoading(true);
      const addedUserObjList = users?.filter((obj) => usersList?.includes(obj?.email));
      let addedUser;
      let url;
      let urlMethod;
      if (userModal === "UpdateUsers") {
        url = "update";
        urlMethod = "PUT";
        addedUser = usersList;
      } else {
        url = "add";
        urlMethod = "POST";
        addedUser = addedUserObjList?.map((item) => `${item.id}::${item?.email}`);
      }
      let showAllPhoneNumbersView;
      let phoneAccess;
      if (showAllPhoneNumbers === "allNumbers") {
        showAllPhoneNumbersView = true;
        phoneAccess = 1;
      } else {
        showAllPhoneNumbersView = false;
        phoneAccess = 0;
      }
      let cleanedPhoneNumbers;

      if (showAllPhoneNumbers === "assignedNumbersOnly") {
        if (assignPhoneNumber?.length > 0) {
          cleanedPhoneNumbers = assignPhoneNumber?.map((item) => item?.replace(/\s*\(.*?\)/, ""));
          phoneAccess = 2;
        } else {
          setLoading(false);
          failureNotification("Add user", "Please assign a number to add the user.");
          return;
        }
      }
      if (usersList.length > 0) {
        let payload = {
          leftServiceId: appProperties?.leftServiceId,
          rightServiceId: appProperties?.rightServiceId,
          remoteIdentifier: urlParams.get("companyId"),
          users: addedUser,
          role: userRole,
          fullPhoneAccess: showAllPhoneNumbersView,
          numbers: cleanedPhoneNumbers,
          osyncUserId: osyncUsersId,
          phoneAccess: phoneAccess,
        };
        addUsers(appProperties, payload, url, urlMethod).then((response) => {
          if (response !== false) {
            if (response?.status === 200) {
              setUsersList([]);
              var license = response.data;
              const userNumbersMap = license?.numbers?.reduce((acc, obj) => {
                acc[obj.phoneNumber] = obj?.associatedUsers;
                return acc;
              }, {});

              if (license?.allLicensedUserDetails) {
                if (cleanedPhoneNumbers) {
                  setAppProperties((prev) => ({
                    ...prev,
                    allNumbers: prev?.allNumbers?.map((obj) => {
                      if (Object.keys(userNumbersMap)?.includes(obj.phoneNumber)) {
                        return {
                          ...obj,
                          associatedUsers: userNumbersMap?.[obj.phoneNumber],
                        };
                      }
                      return obj;
                    }),
                    licenseObj: license,
                  }));
                } else {
                  appProperties.licenseObj = response.data;
                }
              } else {
                setAppProperties((prev) => ({
                  ...prev,
                  allNumbers: cleanedPhoneNumbers
                    ? prev?.allNumbers?.map((obj) => {
                        if (Object.keys(userNumbersMap).includes(obj.phoneNumber)) {
                          return {
                            ...obj,
                            associatedUsers: userNumbersMap?.[obj.phoneNumber],
                          };
                        }
                        return obj;
                      })
                    : !response?.data?.numbers
                    ? prev?.allNumbers?.map((obj) => {
                        return {
                          ...obj,
                          associatedUsers: obj?.associatedUsers?.filter((userId) => userId !== osyncUsersId),
                        };
                      })
                    : prev?.allNumbers,
                  licenseObj: {
                    ...prev.licenseObj,
                    allLicensedUserDetails: prev.licenseObj.allLicensedUserDetails.map((user) =>
                      user.osyncUserId === license.licensedUserDetails.osyncUserId ? { ...license.licensedUserDetails } : user
                    ),
                  },
                }));
              }

              let responseData = response?.data;
              if (responseData && (responseData?.status === "failed" || responseData?.status === "success_with_failure")) {
                if (responseData?.status === "success_with_failure") {
                  setResponseObj(response);
                  setCallSavedUserTable(true);
                  setIsDrawerOpen(false);
                  successNotifyWithDescription("Add Users", "User added! They're now part of the team." + responseData?.successUserList);
                }
                if (responseData?.existingEmailIdsList) {
                  failureNotificationWithDuration15Seconds("User Already Part of Team", responseData?.existingEmailIdsList);
                }
                if (responseData?.failedUsersList) {
                  setFailedUsersList(responseData?.failedUsersList);
                  setFailurePricing(true);
                }
                setIsLoading(false);
                setLoading(false);
              } else {
                setResponseObj(response);
                setCallSavedUserTable(true);
                setIsDrawerOpen(false);
                setIsLoading(false);
                successNotifyWithDescription("Add Users", "User added! They're now part of the team.");
              }
            }
          } else {
            setIsLoading(false);
            var userLimit = `User limit reached. Contact ${ASSIST_MAIL} to add more users.`;
            failureNotificationWithLink(userLimit, `${ASSIST_MAIL}`, `mailto:${ASSIST_MAIL}`);
          }
        });
      } else {
        setIsLoading(false);
        setLoading(false);
        failureNotification("Add user", "At least one user must be selected to add.");
      }
    }
  };

  const handleClose = () => {
    setDrawerOpen(false);
    setIsDrawerOpen(false);
    setUsersList([]);
  };

  const handleRoleChange = (value, key) => {
    setUserRole(value);
  };
  const handlePhoneAccessChange = (value) => {
    setShowAllPhoneNumbers(value);
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const handleTagRender = () => {
      setAssignPhoneNumber((prev) => prev?.filter((number) => number !== value));
      if (onClose) onClose();
    };

    return (
      <Tag
        className="p-1 mb-1 mt-1 hs-border-15 hs-bg-tag-purple d-flex align-items-center hs-fw-500 user-select-tab"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={handleTagRender}
      >
        <span>{value}</span>
      </Tag>
    );
  };

  return (
    <>
      <Drawer
        className="userDrawer"
        title={userModal === "AddUsers" ? "Add user" : "Edit user"}
        open={drawerOpen}
        onClose={handleClose}
        placement="right"
        width={"30rem"}
        height={"100rem"}
        footer={
          <div className=" templatesAddButton ">
            <Button className="templateAdd" type="" size="large" onClick={addUser} loading={loading}>
              {userModal === "AddUsers" ? "Add" : "Update"}
            </Button>
          </div>
        }
      >
        <div className="userModal">
          <Row>
            <Col xs={24} md={24}>
              Users
              <div className="mt-2">
                <Select
                  mode="multiple"
                  disabled={userModal === "UpdateUsers" ? true : false}
                  tagRender={tagRender}
                  placeholder="Choose users"
                  className="selectUser focus-border rounded hs-bg-off-white pt-2 hs-bg-off-white hs-w-100"
                  value={usersList}
                  onSelect={(e) => {
                    setUsersList((prev) => [...prev, e]);
                  }}
                  onDeselect={(e) => {
                    setUsersList((prev) => prev?.filter((a) => a !== e));
                  }}
                  loading={loadingUserResponse}
                  bordered={false}
                >
                  {users &&
                    users?.map((user, index) => {
                      return (
                        <Select.Option key={user.id} value={user.email}>
                          {user.firstName} ({user.email})
                        </Select.Option>
                      );
                    })}
                </Select>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={24} md={24}>
              Roles
              <div className="mt-2">
                <Select
                  placeholder="Choose Roles"
                  className="selectUser focus-border rounded hs-bg-off-white hs-fw-500 hs-w-100"
                  value={userRole}
                  onChange={handleRoleChange}
                  bordered={false}
                  loading={loadingUserResponse}
                  options={
                    appProperties?.rolesBasedObject
                      ? Object.entries(appProperties?.rolesBasedObject)
                          .filter(([_, value]) => value !== "ACCOUNT OWNER")
                          .map(([key, value]) => ({ label: value, value: Number(key) }))
                      : []
                  }
                ></Select>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={24} md={24}>
              Phone Number Access
              <div className="mt-2">
                <Select
                  className="selectUser focus-border rounded hs-bg-off-white hs-w-100"
                  bordered={false}
                  loading={loadingUserResponse}
                  onChange={handlePhoneAccessChange}
                  value={showAllPhoneNumbers}
                  options={[
                    {
                      value: "allNumbers",
                      label: "All Numbers",
                    },
                    {
                      value: "systemDefault",
                      label: "System Default",
                    },
                    {
                      value: "assignedNumbersOnly",
                      label: "Assigned Numbers Only",
                    },
                  ]}
                />
              </div>
              <div className="mt-2 hs-color-mediumDark hs-fs-12">
                {
                  {
                    allNumbers: "Access to all phone numbers in the account.",
                    systemDefault: (
                      <>
                        <span className="text-decoration-underline">Twilio & WhatsApp</span>: All numbers will be accessible to everyone.
                        <br />
                        <span className="text-decoration-underline">RingCentral</span>: Users must grant authorization to use their numbers.
                      </>
                    ),
                    assignedNumbersOnly: "Access restricted to specific numbers assigned by the admin.",
                  }[showAllPhoneNumbers]
                }
              </div>
            </Col>

            {showAllPhoneNumbers === "assignedNumbersOnly" && (
              <Col xs={24} md={24} className="mt-4">
                Phone Numbers
                <div className="mt-2">
                  <Select
                    mode="multiple"
                    tagRender={tagRender}
                    placeholder="Choose Phone numbers"
                    className="selectUser focus-border rounded hs-bg-off-white hs-w-100 pt-2"
                    bordered={false}
                    loading={loadingUserResponse}
                    onChange={(values) => {
                      setAssignPhoneNumber(values);
                    }}
                    value={assignPhoneNumber}
                    defaultValue={
                      userModal === "UpdateUsers" && Array.isArray(userUpdateList?.roleBasedNumber) && userUpdateList?.roleBasedNumber.length > 0
                        ? userUpdateList?.roleBasedNumber
                        : []
                    }
                  >
                    {phobneNumberObj?.map((user, index) => (
                      <Select.Option
                        key={index}
                        value={`${user?.phoneNumber} (${
                          appProperties?.installedApps?.find((obj) => obj?.integProps?.integId === user?.integId)?.right?.service_display_name
                        })`}
                      >
                        <span
                          className={
                            "me-2 ps-3 numberlist-sprites users-number-list users-number-list-" +
                            appProperties?.installedApps?.find((obj) => obj?.integProps?.integId === user?.integId)?.right?.service_name
                          }
                        ></span>{" "}
                        <span className="">{user?.phoneNumber}</span>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </Drawer>
    </>
  );
});
